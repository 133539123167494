import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useDateRange } from "../context/date-range-context";
import { DateRangeInputPickerInputContainer } from "./styled-date-range-input-picker";

export const DateRangeInputPicker = () => {
  const {
    onDatesChange,
    onDateRangePickerClick,
    temporalStartDate,
    temporalEndDate,
    dateRangePickerRef,
  } = useDateRange();

  return (
    <DateRangeInputPickerInputContainer>
      <DateRangePickerComponent
        startDate={temporalStartDate ? new Date(temporalStartDate) : undefined}
        endDate={temporalEndDate ? new Date(temporalEndDate) : undefined}
        placeholder="Select date range"
        format="dd/ M /yyyy"
        separator="-"
        onChange={onDatesChange}
        ref={dateRangePickerRef}
        onClick={onDateRangePickerClick}
      />
    </DateRangeInputPickerInputContainer>
  );
};
