import { Temporal } from "temporal-polyfill";
import { DayAggregate } from "../../../../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { CgmSyncfusionChart } from "../../../../../../../../../components/cgm-syncfusion-chart/cgm-syncfusion-chart";
import { ModalForm } from "../../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../../../styles/classes/reusable-classes";
import { useAGPReportLoadedResponse } from "../../../../../context/agp-report-loaded-response-context";
import { getInRangeNumbers } from "../../../../../../../../../utils/patient-type-utils";
import { DailyChartRanges } from "../../../../../../daily-analysis/daily-charts/daily-chart/daily-chart-ranges/daily-chart-ranges";
import { CircleCharts } from "../../../../../../daily-analysis/daily-charts/daily-chart/styled-daily-chart";
import {
  AgpReportResponseChartModalBodyChartContainer,
  AgpReportResponseChartModalBodyChartHeader,
  AgpReportResponseChartModalBodyChartTitle,
  AgpReportResponseChartModalBodyContainer,
  AgpReportResponseChartModalBodyIcon,
} from "./styled-agp-report-response-chart-modal-body";
import { useState } from "react";
import { useDateRange } from "../../../../../../../../../components/date-range/context/date-range-context";
import { DailyFixedSummaryChartCard } from "../../../../../../daily-fixed-summary/daily-analysis-charts/daily-fixed-summary-chart-container/daily-fixed-summary-chart-card/daily-fixed-summary-chart-card";

type AgpReportResponseChartModalBodyProps = {
  closeModal: () => void;
  date: string;
  isModalOpen: boolean;
};

export const AgpReportResponseChartModalBody = (
  props: AgpReportResponseChartModalBodyProps
) => {
  const { closeModal, date, isModalOpen } = props;

  const {
    patientDTO,
    patientActivityLogs,
    patientInsulinLogDTOs,
    patientMealsDTOs,
    patientCGMEntryDTOs,
    groupCgmEntriesByDate,
  } = useAGPReportLoadedResponse();

  const [carouselDate, setCarouselDate] = useState(date);

  const { selectedDates } = useDateRange();

  const handleNext = () => {
    const currentDate = Temporal.PlainDate.from(carouselDate);
    const nextDate = currentDate.add({ days: 1 }).toString();

    if (selectedDates.datesWithinRange.some((item) => item.date === nextDate)) {
      setCarouselDate(nextDate);
    }
  };

  const handlePrevious = () => {
    const currentDate = Temporal.PlainDate.from(carouselDate);
    const previousDate = currentDate.subtract({ days: 1 }).toString();

    if (
      selectedDates.datesWithinRange.some((item) => item.date === previousDate)
    ) {
      setCarouselDate(previousDate);
    }
  };

  const dayAggregate: DayAggregate = {
    date: Temporal.PlainDate.from(carouselDate),
    inRangeNumbers: getInRangeNumbers(patientDTO.type),
    maxGlucose: Math.max(
      ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
    ),
    meals: [],
    medicines: [],
    minGlucose: Math.min(
      ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
    ),
    patientActivityLogs,
    patientCGMEntryDTOs,
    patientInsulinLogDTOs,
    patientMealDTO: patientMealsDTOs,
    rangesCounts: {
      above: 0,
      below: 0,
      inRange: 0,
    },
    readings: [],
  };

  const currentCGMEntriens = groupCgmEntriesByDate.filter(
    (item) => item.date === carouselDate
  )[0].readings;

  const currentMeals = patientMealsDTOs.filter(
    (item) => item.date === carouselDate
  );

  return (
    <ModalForm height={500} width={900}>
      <ModalHeader
        title={`${carouselDate} Daily Fixed Summary`}
        onModalClose={closeModal}
      />
      {isModalOpen && (
        <AgpReportResponseChartModalBodyContainer>
          <AgpReportResponseChartModalBodyIcon
            onClick={handlePrevious}
            className="material-symbols-outlined"
          >
            chevron_left
          </AgpReportResponseChartModalBodyIcon>
          <AgpReportResponseChartModalBodyChartContainer>
            <AgpReportResponseChartModalBodyChartHeader>
              <AgpReportResponseChartModalBodyChartTitle>
                <p>Daily graph</p>
                <span>Blood glucose reading</span>
              </AgpReportResponseChartModalBodyChartTitle>
              <CircleCharts>
                {currentCGMEntriens.length > 0 && (
                  <DailyChartRanges
                    smallCircles={false}
                    dayAggregate={{
                      ...dayAggregate,
                      patientCGMEntryDTOs: currentCGMEntriens,
                    }}
                    patientDTO={patientDTO}
                  />
                )}
              </CircleCharts>
            </AgpReportResponseChartModalBodyChartHeader>
            <CgmSyncfusionChart
              patientDTO={patientDTO}
              dayAggregate={dayAggregate}
              zindex={10000}
              hasTooltip={false}
              enableLegend
              cgmChartMarkerTemplate={[
                "Patient Meals",
                "Insulin",
                "Activities",
                "BGM",
              ]}
            />
          </AgpReportResponseChartModalBodyChartContainer>
          <DailyFixedSummaryChartCard
            dayAggregate={{
              ...dayAggregate,
              patientCGMEntryDTOs: currentCGMEntriens,
              patientMealDTO: currentMeals,
            }}
          />
          <AgpReportResponseChartModalBodyIcon
            className="material-symbols-outlined"
            onClick={handleNext}
          >
            chevron_right
          </AgpReportResponseChartModalBodyIcon>
        </AgpReportResponseChartModalBodyContainer>
      )}
      <ModalFooter>
        <Button type="button" outlined onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
