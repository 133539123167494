import { AGPReportResponseGlucoseStatistics } from "./agp-report-response-glucose-statistics/agp-report-response-glucose-statistics";
import { AGPReportResponseTimeRanges } from "./agp-report-response-time-ranges/agp-report-response-time-ranges";
import { AGPReportResponseCardsContainer } from "./styled-agp-report-response-cards";

export const AGPReportResponseCards = () => {
  return (
    <AGPReportResponseCardsContainer>
      <AGPReportResponseTimeRanges />
      <AGPReportResponseGlucoseStatistics />
    </AGPReportResponseCardsContainer>
  );
};
