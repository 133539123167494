import {
  ChartComponent,
  DateTime,
  Inject,
  ScatterSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { useCallback, useMemo, useRef } from "react";
import { buildAxisLabelRender } from "./utils/build-axis-label-render";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";
import { buildPrimaryYAxis } from "./utils/build-primary-y-axis";
import { buildOverlaySeries } from "./utils/series/build-overlay-series";
import { useAGPReportLoadedResponse } from "../../../context/agp-report-loaded-response-context";
import { randomUUID } from "../../../../../../../utils/random-uuid";
import { getInRangeNumbers } from "../../../../../../../utils/patient-type-utils";
import { useAGPDaysFilter } from "../../../context/agp-days-filter-context";
import { useDateRange } from "../../../../../../../components/date-range/context/date-range-context";
import { AgpReportResponseOverlayContainer } from "./styled-agp-report-response-overlay";
import { filterdByDaysGroupCgmEntriesByDate } from "../../utils/group-cgm-entries-by-date-filterd-by-days";

export const AgpReportResponseOverlay = () => {
  const {
    patientDTO,
    patientCGMEntryDTOs,
    groupCgmEntriesByDate,
  } = useAGPReportLoadedResponse();

  const chartRef = useRef<ChartComponent | null>(null);
  const id = useMemo(() => `overlay-syncfusion-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);

    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const primaryxAxis = useMemo(
    () =>
      buildPrimaryXAxis(
        new Date(`2024-01-01T00:00:00`),
        new Date(`2024-01-02T00:00:00`)
      ),
    []
  );

  const minY = useMemo(
    () =>
      Math.min(
        ...[40, ...patientCGMEntryDTOs.map((entry) => entry.glucoseMGPerDL)]
      ),
    [patientCGMEntryDTOs]
  );

  const maxY = 350;

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const primaryyAxis = useMemo(() => {
    const title = "";

    return buildPrimaryYAxis(minY, maxY, inRangeNumbers, title);
  }, [minY, maxY, inRangeNumbers]);

  const vAxisTicks = useMemo(() => {
    const ticks = [0, 54, inRangeNumbers[0], inRangeNumbers[1], 250, maxY];

    return ticks;
  }, [inRangeNumbers, maxY]);

  const axisLabelRender = useMemo(() => buildAxisLabelRender(vAxisTicks), [
    vAxisTicks,
  ]);

  const { daysFilter } = useAGPDaysFilter();
  const { selectedDates } = useDateRange();

  const filteredPatientCgmEntriesByWeekday = useMemo(
    () =>
      filterdByDaysGroupCgmEntriesByDate(
        groupCgmEntriesByDate,
        daysFilter,
        selectedDates.datesWithinRange
      ),
    [selectedDates.datesWithinRange, daysFilter, groupCgmEntriesByDate]
  );

  const overlaySeries = useMemo(
    () => buildOverlaySeries(filteredPatientCgmEntriesByWeekday),
    [filteredPatientCgmEntriesByWeekday]
  );

  return (
    <AgpReportResponseOverlayContainer>
      <ChartComponent
        id={id}
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        axisLabelRender={axisLabelRender}
        ref={chartRef}
        loaded={handleChartLoaded}
        height="482px"
        width="100%"
        enableAnimation={false}
      >
        <Inject services={[StripLine, DateTime, ScatterSeries]} />
        <SeriesCollectionDirective>
          {overlaySeries.map((item) => (
            <SeriesDirective
              dataSource={item.series}
              animation={{ enable: false }}
              xName="x"
              yName="y"
              type="Scatter"
              name={`Overlay: ${item.day}s`}
              fill={item.seriesColor}
              marker={{
                visible: true,
                height: 5,
                width: 5,
                fill: item.seriesColor,
              }}
              width={2}
              opacity={1}
            ></SeriesDirective>
          ))}
        </SeriesCollectionDirective>
      </ChartComponent>
    </AgpReportResponseOverlayContainer>
  );
};
