import styled from "styled-components";
import {
  cardTitleFont,
  flexCenterSpaceBetween,
} from "../../../../../../../../../styles/classes/gloabl-classes";
import { DailyFixedSummaryChartCardContainer } from "../../../../../../daily-fixed-summary/daily-analysis-charts/daily-fixed-summary-chart-container/daily-fixed-summary-chart-card/styled-daily-fixed-summary-chart-card";

export const AgpReportResponseChartModalBodyContainer = styled.div`
  position: relative;
  ${flexCenterSpaceBetween}
  padding: 12px 0px;
  gap: 0px 8px;

  ${DailyFixedSummaryChartCardContainer} {
    height: 100%;
  }
`;

export const AgpReportResponseChartModalBodyIcon = styled.span`
  user-select: none;
  cursor: pointer;
`;

export const AgpReportResponseChartModalBodyChartContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 12px;
  width: 70%;
`;

export const AgpReportResponseChartModalBodyChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  margin-bottom: auto;
  height: 90px;
`;

export const AgpReportResponseChartModalBodyChartTitle = styled.div`
  p {
    ${cardTitleFont}
    margin: 0rem 0rem 0.5rem;

    @media screen and (max-width: 420px) {
      font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    }
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    font-weight: ${({ theme }) => theme.typography.fontWeight.regular};

    @media screen and (max-width: 420px) {
      font-size: ${({ theme }) => theme.typography.fontSize.regularSmFont};
    }
  }
`;
