import {
  ChartComponent,
  DateTime,
  IAxisLabelRenderEventArgs,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineRangeAreaSeries,
  SplineSeries,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { useCallback, useMemo, useRef } from "react";
import { randomUUID } from "../../../../../../../utils/random-uuid";
import { getInRangeNumbers } from "../../../../../../../utils/patient-type-utils";
import { buildAgpSeries95th5th } from "../../agp-report-response-agp/agp-report-response-agp-graph/utils/series/build-agp-series-95th-5th";
import { buildAgpSeries75th25th } from "../../agp-report-response-agp/agp-report-response-agp-graph/utils/series/build-agp-series-75th-25th";
import { buildAgpSeries50th } from "../../agp-report-response-agp/agp-report-response-agp-graph/utils/series/build-agp-series-50th";
import { useAGPReportResponseMiniAGP } from "./agp-report-response-mini-agp-context";
import { useAGPReportLoadedResponse } from "../../../context/agp-report-loaded-response-context";
import { buildPrimaryYAxis } from "./utils/build-primary-y-axis";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";

type AGPReportResponseMiniAGPProps = {
  isDimmed: boolean;
};

export const AGPReportResponseMiniAGP = (
  props: AGPReportResponseMiniAGPProps
) => {
  const { isDimmed } = props;

  const { patientDTO } = useAGPReportLoadedResponse();
  const {
    percentile5,
    percentile25,
    percentile50,
    percentile75,
    percentile95,
  } = useAGPReportResponseMiniAGP();

  const chartRef = useRef<ChartComponent | null>(null);

  const id = useMemo(() => `agp-syncfusion-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);

    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const maxY = 350;

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const primaryYAxis = useMemo(() => buildPrimaryYAxis(maxY, inRangeNumbers), [
    maxY,
    inRangeNumbers,
  ]);

  const primaryXAxis = useMemo(() => buildPrimaryXAxis(), []);

  const agpSeries95th5th = useMemo(
    () => buildAgpSeries95th5th(percentile5, percentile95),
    [percentile5, percentile95]
  );

  const agpSeries75th25th = useMemo(
    () => buildAgpSeries75th25th(percentile25, percentile75),
    [percentile25, percentile75]
  );

  const agpSeries50th = useMemo(() => buildAgpSeries50th(percentile50), [
    percentile50,
  ]);

  return (
    <ChartComponent
      id={id}
      primaryXAxis={primaryXAxis}
      primaryYAxis={primaryYAxis}
      axisLabelRender={(args: IAxisLabelRenderEventArgs) => {
        args.labelStyle.color = "transparent";
      }}
      ref={chartRef}
      loaded={handleChartLoaded}
      height="80px"
      width="100%"
      style={isDimmed ? { opacity: 0.5 } : { opacity: 1 }}
    >
      <Inject
        services={[SplineRangeAreaSeries, StripLine, SplineSeries, DateTime]}
      />
      <SeriesCollectionDirective>
        <SeriesDirective
          dataSource={agpSeries95th5th}
          xName="x"
          high="high95th"
          low="low5th"
          opacity={0.4}
          type="SplineRangeArea"
          fill="#418AC0"
          dashArray="5,5"
          border={{
            color: "#ACB1C0",
            width: 4,
          }}
        ></SeriesDirective>
        <SeriesDirective
          dataSource={agpSeries75th25th}
          xName="x"
          high="high75th"
          low="low25th"
          opacity={1}
          fill="#85A6D0"
          type="SplineRangeArea"
          border={{
            color: "transparent",
            width: 0,
          }}
        ></SeriesDirective>
        <SeriesDirective
          dataSource={agpSeries50th}
          xName="x"
          yName="point50th"
          opacity={1}
          type="Spline"
          width={2}
          fill="#000"
        ></SeriesDirective>
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};
