import styled from "styled-components/macro";
import {
  flexCol,
  mediumRegularFont,
  mediumSemiBoldFont,
  regularRegularFont,
} from "../../../../../../styles/classes/gloabl-classes";
import { lightShadowAlt } from "../../../../../../styles/global-style";

export const BGMResponseBodyContainer = styled.div`
  ${flexCol}
  gap: 24px 16px;
  padding: 0px 0px 24px;
`;

export const BGMCard = styled.div`
  ${lightShadowAlt}
  border-radius: 8px;
  width: 90%;
  margin: 0 auto;
`;

export const BGMCardHeader = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;

  p {
    ${mediumSemiBoldFont}
    margin: 0px;
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    ${mediumRegularFont}
  }
`;

export const BGMCardFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 8px;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    p {
      ${mediumRegularFont}
      color: ${({ theme }) => theme.colors.textColor};
      margin: 0px;
    }
    
    span {
      ${regularRegularFont}
      color: ${({ theme }) => theme.colors.titleColor};
    }
  }
`;
