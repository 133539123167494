import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
} from "../../../../../../../styles/classes/gloabl-classes";

export const AgpReportResponseDaysFilterCheckboxesContainer = styled.div`
  ${flexCenter}
  margin-top: 16px;
  position: relative;
`;

export const AgpReportResponseDaysFilterCheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: #fff;
  background-color: ${({ theme }) => theme.colors.inputColor};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};

  :after {
    content: "";
    display: none;
    position: absolute;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const AgpReportResponseDaysFilterCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked ~ ${AgpReportResponseDaysFilterCheckMark} {
    background: ${({ theme }) => theme.colors.primaryColorAlt};

    ::after {
      display: block;
    }
  }
`;

export const AgpReportResponseDaysFilterCheckboxWrapper = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${flexCenterCenter}

  :hover ${AgpReportResponseDaysFilterCheckMark} {
    ::after {
      display: block;
    }
  }

  :nth-child(2) {
    margin-left: auto;
  }

  :last-child {
    margin-left: auto;
  }

  &.weekdays-checkbox {
    width: 75%;

    ${AgpReportResponseDaysFilterCheckMark} {
      left: 42%;
    }

    ::before {
      content: "";
      position: absolute;
      width: 37%;
      height: 20px;
      border: 3px solid ${({ theme }) => theme.colors.borderColor};
      border-bottom: 0px;
      border-left: 0px;
      border-top-right-radius: 8px;
      top: 45%;
      right: 20px;
    }

    ::after {
      content: "";
      position: absolute;
      width: 39%;
      height: 20px;
      border: 3px solid ${({ theme }) => theme.colors.borderColor};
      border-bottom: 0px;
      border-right: 0px;
      border-top-left-radius: 8px;
      top: 45%;
      left: 10px;
    }
  }

  &.weekends-checkbox {
    width: 25%;

    ${AgpReportResponseDaysFilterCheckMark} {
      left: 24%;
    }

    ::before {
      content: "";
      position: absolute;
      width: 20%;
      height: 20px;
      border: 3px solid ${({ theme }) => theme.colors.borderColor};
      border-bottom: 0px;
      border-left: 0px;
      border-top-right-radius: 8px;
      top: 45%;
      right: 0px;
    }

    ::after {
      content: "";
      position: absolute;
      width: 20%;
      height: 20px;
      border: 3px solid ${({ theme }) => theme.colors.borderColor};
      border-bottom: 0px;
      border-right: 0px;
      border-top-left-radius: 8px;
      top: 45%;
      left: 5px;
    }
  }
`;
