import { useCallback, useMemo } from "react";
import { buildPrimaryDateTimeXAxis } from "../../../../utils/bgm-charts-utils/build-primary-date-time-x-axis";
import { getInRangeNumbers } from "../../../../../../../../../utils/patient-type-utils";
import { buildPrimaryYAxis } from "../../../../utils/bgm-charts-utils/build-primary-y-axis";
import { buildAxisLabelRender } from "../../../../utils/bgm-charts-utils/build-axis-label-render";
import { buildBGMGlucoseTagSeries } from "../utils/build-bgm-glucose-tag-series";
import { PatientCGMEntryDTOWithBgmTags } from "../../../../utils/types";
import { useBGMResponse } from "../../../../../context/bgm-response-context";
import {
  ChartComponent,
  ColumnSeries,
  DataLabel,
  DateTime,
  Inject,
  Legend,
  SeriesCollectionDirective,
  SeriesDirective,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { randomUUID } from "../../../../../../../../../utils/random-uuid";

type BGMGlucoseTagBreakfastChartProps = {
  beforeBreakfastReadings: PatientCGMEntryDTOWithBgmTags[];
  afterBreakfastReadings: PatientCGMEntryDTOWithBgmTags[];
  breakfastReadings: PatientCGMEntryDTOWithBgmTags[];
};

export const BGMGlucoseTagBreakfastChart = (
  props: BGMGlucoseTagBreakfastChartProps
) => {
  const {
    beforeBreakfastReadings,
    afterBreakfastReadings,
    breakfastReadings,
  } = props;

  const { patientDTO } = useBGMResponse();

  const id = useMemo(
    () => `bgm-glucose-tag-breakfast-chart-${randomUUID()}`,
    []
  );

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);
    // Should always enter this condition, but let's be safe
    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const primaryXAxis = useMemo(() => buildPrimaryDateTimeXAxis(), []);

  const minY = useMemo(
    () =>
      Math.min(
        ...[40, ...breakfastReadings.map((entry) => entry.glucoseMGPerDL)]
      ),
    [breakfastReadings]
  );

  const { maxY } = useMemo(() => {
    let maxGlucose = Math.max(
      ...[40, ...breakfastReadings.map((entry) => entry.glucoseMGPerDL)]
    );
    const maxY = maxGlucose > 200 ? maxGlucose + 50 : 250;

    return { maxY: Math.floor(maxY / 10) * 10 };
  }, [breakfastReadings]);

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const primaryYAxis = useMemo(
    () => buildPrimaryYAxis(minY, maxY, inRangeNumbers),
    [minY, maxY, inRangeNumbers]
  );

  const vAxisTicks = useMemo(() => [...inRangeNumbers, maxY], [
    inRangeNumbers,
    maxY,
  ]);

  const axisLabelRender = useMemo(() => buildAxisLabelRender(vAxisTicks), [
    vAxisTicks,
  ]);

  const { beforeBreakfastSeries } = useMemo(() => {
    const beforeBreakfastSeries = buildBGMGlucoseTagSeries(
      beforeBreakfastReadings
    );

    return { beforeBreakfastSeries };
  }, [beforeBreakfastReadings]);

  const { afterBreakfastSeries } = useMemo(() => {
    const afterBreakfastSeries = buildBGMGlucoseTagSeries(
      afterBreakfastReadings
    );

    return { afterBreakfastSeries };
  }, [afterBreakfastReadings]);

  return (
    <ChartComponent
      id={id}
      loaded={handleChartLoaded}
      primaryXAxis={primaryXAxis}
      primaryYAxis={primaryYAxis}
      axisLabelRender={axisLabelRender}
      height="300px"
      width="100%"
      legendSettings={{
        visible: true,
        position: "Bottom",
      }}
    >
      <Inject
        services={[DataLabel, ColumnSeries, StripLine, DateTime, Legend]}
      />
      <SeriesCollectionDirective>
        <SeriesDirective
          dataSource={beforeBreakfastSeries}
          name="Before Breakfast"
          xName="x"
          yName="y"
          type="Column"
          fill="#2d9bf0"
          columnWidth={0.1}
          legendShape="Circle"
          columnWidthInPixel={10}
        ></SeriesDirective>
        <SeriesDirective
          dataSource={afterBreakfastSeries}
          name="After Breakfast"
          xName="x"
          yName="y"
          type="Column"
          fill="#00086b"
          columnWidth={0.1}
          legendShape="Circle"
          columnWidthInPixel={10}
        ></SeriesDirective>
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};
