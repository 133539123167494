import styled, { css } from "styled-components/macro";
import { lightShadowAlt, transition } from "../../styles/global-style";
import { VariantType } from "./dropdown";
import {
  flexCenter,
  flexCenterSpaceBetween,
  mediumRegularFont,
  regularSemiBoldFont,
} from "../../styles/classes/gloabl-classes";

type DropdownButtonType = {
  showDropdown: boolean;
  variant: VariantType;
  disabled?: boolean;
  titleColor: boolean;
};

const DropdownButtonCSS = css<DropdownButtonType>`
  ${flexCenterSpaceBetween}
  padding: 0rem;
  padding-left: 0.5rem;
  border-radius: 0.5rem;
  background: ${({ theme, variant }) =>
    variant === "fill"
      ? theme.colors.primaryColor
      : variant === "outline-dark"
      ? theme.colors.inputColor
      : "transparent"};

  border: 1px solid
    ${({ theme, variant }) =>
      variant === "fill" ? "transparent" : theme.colors.borderColor};

  color: ${({ theme, variant }) =>
    variant === "fill"
      ? theme.colors.buttonColor
      : variant === "outline-dark"
      ? theme.colors.titleColor
      : theme.colors.textColor};

  font-size: ${({ theme, variant }) =>
    variant === "outline-dark"
      ? theme.typography.fontSize.smFontSize
      : theme.typography.fontSize.regularFont};

  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
  border-bottom-left-radius: ${({ showDropdown }) =>
    showDropdown ? "0rem" : "0.5rem"};

  border-bottom-right-radius: ${({ showDropdown }) =>
    showDropdown ? "0rem" : "0.5rem"};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  transition: ${transition};
  height: 48px;
  padding: 0rem 0rem 0rem 0.5rem;

  .add-icon {
    margin-right: 0.4rem;
  }

  p {
    margin: 0;
  }

  @media screen and (max-width: 750px) {
    width: 150px;
  }

  @media screen and (max-width: 425px) {
    span {
      display: none;

      :last-child {
        display: flex;
      }
    }
  }

  @media screen and (max-width: 330px) {
    padding: 0rem 0.3rem;
  }
`;

const DesignedUxDropdownButtonCSS = css<DropdownButtonType>`
  ${flexCenterSpaceBetween}
  background: ${({ theme }) => theme.colors.inputColor};
  color: ${({ theme, titleColor }) =>
    titleColor ? theme.colors.titleColor : theme.colors.textColor};
  padding: 8px 16px;
  width: 300px;
  border-radius: 8px;
  ${mediumRegularFont}

  span {
    color: ${({ theme }) => theme.colors.textColor};
    cursor: pointer;
    transform: ${({ showDropdown }) =>
      showDropdown ? "rotate(-180deg)" : "rotate(0deg)"};
    transition: ${transition};
  }
`;

export const DropdownButton = styled.button<DropdownButtonType>`
  ${({ variant }) =>
    variant === "designed-ux"
      ? DesignedUxDropdownButtonCSS
      : DropdownButtonCSS};
`;

type DropdownContainerType = {
  fullWidth?: boolean;
  width?: number;
  modalDropdown?: boolean;
  variant?: VariantType;
};

const DropdownContainerCSS = css<DropdownContainerType>`
  position: relative;
  height: 48px;
  width: ${({ fullWidth, variant }) =>
    fullWidth ? "100%" : variant === "pagination" ? "50px" : "fit-content"};

  ${DropdownButton} {
    width: ${({ fullWidth, width, variant }) =>
      fullWidth
        ? "100%"
        : width
        ? `${width}px`
        : variant === "pagination"
        ? "50px"
        : "190px"};

    @media screen and (max-width: 425px) {
      width: ${({ modalDropdown, fullWidth, width }) =>
        fullWidth || modalDropdown
          ? "100%"
          : width
          ? `${width}px`
          : "fit-contnet"};
    }
  }

  @media screen and (max-width: 425px) {
    width: ${({ modalDropdown, fullWidth }) =>
      fullWidth || modalDropdown ? "100%" : "fit-contnet"};
  }
`;

const DesignedUxDropdownContainerCSS = css<DropdownContainerType>`
  ${flexCenter}
  background: ${({ theme }) => theme.colors.inputColor};
  border-radius: 5px;
  padding: ${({ theme }) => theme.padding.py_05};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "300px")};
  height: 40px;
  position: relative;
`;

export const DropdownContainer = styled.div<DropdownContainerType>`
  ${({ variant }) =>
    variant === "designed-ux"
      ? DesignedUxDropdownContainerCSS
      : DropdownContainerCSS};
`;

export const DropdownButtonIcon = styled.span<{
  showDropdown: boolean;
  variant: VariantType;
  disabled?: boolean;
}>`
  background: ${({ variant, theme }) =>
    variant === "fill"
      ? theme.newColors.dropdownIconBlue
      : variant === "pagination"
      ? "transparent"
      : theme.newColors.dropdownIconWhite};
  color: ${({ theme, variant }) =>
    variant === "fill" ? theme.colors.buttonColor : theme.colors.textColor};
  border: 0;
  margin-left: auto;
  border-left: 1px solid
    ${({ theme, variant }) =>
      variant === "fill" || variant === "pagination"
        ? "transparent"
        : theme.colors.borderColor};
  height: 100%;
  display: ${({ disabled, variant }) => (disabled ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  width: 50px;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  span {
    transition: ${transition};
    transform: ${({ showDropdown }) =>
      showDropdown ? "rotate(-180deg)" : "rotate(0deg)"};
  }

  @media screen and (max-width: 400px) {
    width: 30px;
  }

  @media screen and (max-width: 330px) {
    background: transparent;
    color: ${({ theme, variant }) =>
      variant === "fill" ? theme.colors.buttonColor : theme.colors.titleColor};
    border: 0;
  }
`;

type DropdownListType = { showDropdown: boolean; variant: VariantType };

const DropdownListCSS = css<DropdownListType>`
  position: absolute;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  top: 31px;
  left: 0;
  transition: ${transition};
  width: 100%;
  background: ${({ theme }) => theme.colors.containerColor};
  max-height: 165px;
  height: ${({ showDropdown }) => (showDropdown ? "max-content" : "0px")};
  overflow: ${({ showDropdown }) => (showDropdown ? "visible" : "hidden")};
  opacity: ${({ showDropdown }) => (showDropdown ? "1" : "0")};
  border-radius: 0px 0px 8px 8px;
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndex.z300};
`;

const DesignedUxDropdownListCSS = css<DropdownListType>`
  max-height: ${({ showDropdown }) => (showDropdown ? "206px" : "0px")};
  height: fit-content;
  overflow-y: ${({ showDropdown }) => (showDropdown ? "auto" : "hidden")};
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  inset: 0;
  top: 24px;
  z-index: ${({ theme }) => theme.zIndex.z300};
  background: ${({ theme }) => theme.colors.containerColor};
  ${lightShadowAlt}
  border-radius: 1rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  transition: ${({ showDropdown }) => (showDropdown ? transition : "")};
`;

export const DropdownList = styled.ul<DropdownListType>`
  ${({ variant }) =>
    variant === "designed-ux" ? DesignedUxDropdownListCSS : DropdownListCSS};
`;

type DropdownListItemType = { active?: boolean; variant?: VariantType };

const DropdownListItemCSS = css<DropdownListItemType>`
  text-align: center;
  padding: 0.3rem 0rem;
  cursor: pointer;
  transition: ${transition};
  color: ${({ theme, active }) =>
    active ? theme.colors.buttonColor : theme.colors.textColor};
  ${regularSemiBoldFont}
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  position: relative;
  background: ${({ theme, active }) =>
    active ? theme.colors.primaryColorAlt : theme.colors.containerColor};

  :hover {
    background: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.buttonColor};
  }

  :last-child {
    border: 0;
    border-radius: 0px 0px 8px 8px;
  }
`;

const DesignedUxDropdownListItemCSS = css<{ active?: boolean }>`
  ${mediumRegularFont}
  cursor: pointer;
  width: 100%;
  padding: 10px 16px;
  transition: ${transition};

  :hover {
    background: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.buttonColor};
  }

  background: ${({ theme, active }) =>
    active ? theme.colors.primaryColor : theme.colors.containerColor};
  color: ${({ theme, active }) =>
    active ? theme.colors.buttonColor : theme.colors.titleColor};
`;

export const DropdownListItem = styled.li<DropdownListItemType>`
  ${({ variant }) =>
    variant === "designed-ux"
      ? DesignedUxDropdownListItemCSS
      : DropdownListItemCSS};
`;
