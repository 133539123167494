import styled from "styled-components/macro";
import {
  lightShadowAlt,
  transition,
} from "../../../../../../styles/global-style";
import {
  flexCenterCenter,
  mediumFont,
  mediumSemiBoldFont,
} from "../../../../../../styles/classes/gloabl-classes";
import { AGPReportResponseDaysFilterContainer } from "../agp-report-response-days-filter/styled-agp-report-response-days-filter";

export const AGPReportResponseAGPContainer = styled.div`
  padding: 0px 24px;
`;

export const AGPReportResponseAGPCard = styled.div`
  ${lightShadowAlt}
  border-radius: 8px;
  margin-top: 32px;
`;

export const AGPReportResponseAGPHeader = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;

  p {
    ${mediumSemiBoldFont}
    margin: 0px;
  }
`;

export const AGPReportResponseAGPBody = styled.div<{ $isEmpty: boolean }>`
  padding: 8px 16px;
  height: calc(100% - 37px);
  overflow-x: hidden;
  display: ${({ $isEmpty }) => ($isEmpty ? "block" : "grid")};

  ${AGPReportResponseDaysFilterContainer} {
    position: static;
  }
`;

export const AGPReportResponseAGPNavbar = styled.div`
  ${flexCenterCenter}
  padding: 16px 8px;
`;

export const AGPReportResponseAGPButton = styled.button<{ $active: boolean }>`
  background: ${({ theme, $active }) =>
    $active
      ? `${theme.colors.primaryColorAlt}1a`
      : theme.colors.containerColor};
  border: 1px solid
    ${({ theme, $active }) =>
      $active ? theme.colors.primaryColorAlt : theme.colors.borderColor};
  color: ${({ theme, $active }) =>
    $active ? theme.colors.primaryColorAlt : theme.colors.titleColor};
  padding: 4px;
  width: 120px;
  transition: ${transition};
  ${mediumFont}

  :hover {
    color: ${({ theme }) => theme.colors.primaryColorAlt};
    border: 1px solid ${({ theme }) => theme.colors.primaryColorAlt};
    background: ${({ theme }) => theme.colors.primaryColorAlt}1a;
  }
`;
