import { AxisModel } from "@syncfusion/ej2-react-charts";

export function buildPrimaryXAxis(): AxisModel {
  return {
    valueType: "DateTime",
    visible: false,
    edgeLabelPlacement: "Shift",
    labelFormat: "hh:mm",
    majorGridLines: {
      color: "#0000001f",
      width: 2,
    },
    interval: 3,
    minimum: new Date(`2024-01-01T00:00:00`),
    maximum: new Date(`2024-01-02T00:00:00`),
  };
}
