import { PatientCGMEntryDTO } from "../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientType } from "../../../../../../../../models/patient-dtos/patient-type";
import { roundTo1DecimalPlace } from "../../../../../../../../utils/math-utils";
import { calculateRanges } from "./calculate-ranges";
import { calculateReadingsTimeDiffrenceTotal } from "./calculate-time-difference-total";
import { calculateTimeDifferencesTotal } from "./calculate-time-differences-total";

export function calculateDailyChartRanges(
  patientType: PatientType,
  readings: PatientCGMEntryDTO[]
) {
  const readingsWithTimeDifferenceValue = calculateTimeDifferencesTotal(
    readings
  );

  const readingsTimeDifferanceTotal = calculateReadingsTimeDiffrenceTotal(
    readingsWithTimeDifferenceValue
  );

  const {
    belowRangeReadingsTimeDiffrenceTotal,
    inRangeReadingsTimeDiffrenceTotal,
    aboveRangeReadingsTimeDiffrenceTotal,
  } = calculateRanges(patientType, readingsWithTimeDifferenceValue);

  const inRangePercentage = roundTo1DecimalPlace(
    (inRangeReadingsTimeDiffrenceTotal / readingsTimeDifferanceTotal) * 100
  );

  const aboveRangePercentage = roundTo1DecimalPlace(
    (aboveRangeReadingsTimeDiffrenceTotal / readingsTimeDifferanceTotal) * 100
  );

  const belowRangePercentage = roundTo1DecimalPlace(
    (belowRangeReadingsTimeDiffrenceTotal / readingsTimeDifferanceTotal) * 100
  );

  return { belowRangePercentage, inRangePercentage, aboveRangePercentage };
}
