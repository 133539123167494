import { DatesWithinRange } from "../context/date-range-context";

export function getDatesInRange(startDate: Date, endDate: Date) {
  // Convert the start and end dates to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Array to hold the dates
  let dateArray: DatesWithinRange[] = [];

  // Iterate from the start date to the end date
  let currentDate = new Date(start);
  while (currentDate <= end) {
    // Format the date as YYYY-MM-DD string and add it to the array

    dateArray.push({
      weekDay: currentDate.toLocaleDateString("en-US", { weekday: "long" }),
      date: currentDate.toISOString().split("T")[0],
    });
    // Increment the current date by one day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}
