import {
  AGPReportResponseAGPBody,
  AGPReportResponseAGPCard,
  AGPReportResponseAGPHeader,
  AGPReportResponseAGPContainer,
  AGPReportResponseAGPNavbar,
  AGPReportResponseAGPButton,
} from "./styled-agp-report-response-agp";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { AGPReportResponseAGPGraph } from "./agp-report-response-agp-graph/agp-report-response-agp-graph";
import { useAGPReportLoadedResponse } from "../../context/agp-report-loaded-response-context";
import { AGPReportResponseDaysFilter } from "../agp-report-response-days-filter/agp-report-response-days-filter";
import { useAgpOrOverlay } from "../../context/agp-or-overlay-context";
import { AgpReportResponseOverlay } from "./agp-report-response-overlay/agp-report-response-overlay";

export const AGPReportResponseAGP = () => {
  const { groupCgmEntriesByDate } = useAGPReportLoadedResponse();
  const { switchAgp, setSwitchAgp } = useAgpOrOverlay();

  if (groupCgmEntriesByDate.length === 0) {
    return (
      <AGPReportResponseAGPContainer>
        <AGPReportResponseAGPCard>
          <AGPReportResponseAGPHeader>
            <p>AGP Graph</p>
          </AGPReportResponseAGPHeader>
          <AGPReportResponseAGPBody $isEmpty>
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="No Data Within the selected dates"
              title=""
            />
          </AGPReportResponseAGPBody>
        </AGPReportResponseAGPCard>
      </AGPReportResponseAGPContainer>
    );
  }

  if (groupCgmEntriesByDate.length > 0 && groupCgmEntriesByDate.length < 14) {
    return (
      <AGPReportResponseAGPContainer>
        <AGPReportResponseAGPCard>
          <AGPReportResponseAGPHeader>
            <p>AGP Graph</p>
          </AGPReportResponseAGPHeader>
          <AGPReportResponseAGPBody $isEmpty>
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="Please select a date range with 14 Days or more days!"
              title=""
            />
          </AGPReportResponseAGPBody>
        </AGPReportResponseAGPCard>
      </AGPReportResponseAGPContainer>
    );
  }

  return (
    <AGPReportResponseAGPContainer>
      <AGPReportResponseAGPCard>
        <AGPReportResponseAGPHeader>
          <p>{switchAgp} Graph</p>
        </AGPReportResponseAGPHeader>
        <AGPReportResponseAGPNavbar>
          <AGPReportResponseAGPButton
            type="button"
            $active={switchAgp === "AGP"}
            onClick={() => setSwitchAgp("AGP")}
          >
            AGP
          </AGPReportResponseAGPButton>
          <AGPReportResponseAGPButton
            type="button"
            $active={switchAgp === "Overlay"}
            onClick={() => setSwitchAgp("Overlay")}
          >
            Overlay
          </AGPReportResponseAGPButton>
        </AGPReportResponseAGPNavbar>
        <AGPReportResponseAGPBody $isEmpty={false}>
          <AGPReportResponseDaysFilter />
          {switchAgp === "AGP" ? (
            <AGPReportResponseAGPGraph />
          ) : (
            <AgpReportResponseOverlay />
          )}
        </AGPReportResponseAGPBody>
      </AGPReportResponseAGPCard>
    </AGPReportResponseAGPContainer>
  );
};
