import { PatientCGMEntryDTO } from "../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientDTO } from "../../../../../../../models/patient-dtos/patient-dto";
import { PatientType } from "../../../../../../../models/patient-dtos/patient-type";
import { calculateTimeDifferences } from "../../../../../../../utils/glucose-point-utils/calculate-time-differences";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";
import { GroupCgmEntriesByDate } from "../../../context/agp-report-loaded-response-context";

// Determine if a glucose point is in range based on patient type and tag
export function isGlucosePointInRange(
  type: PatientType,
  glucoseMGPerDL: number,
  tag?: string
) {
  if (tag === "fasting") {
    if (type === "NormalOrHealthy" || type === "Prediabetic") {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 100;
    } else if (type === "Type1Pediatric") {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 144;
    } else {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 130;
    }
  } else {
    if (type === "NormalOrHealthy" || type === "Prediabetic") {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 140;
    } else {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 180;
    }
  }
}

// Add timeDifference to each reading
function calculateTimeDifferencesTotal(
  readings: PatientCGMEntryDTO[]
): (PatientCGMEntryDTO & { timeDifference: number })[] {
  return readings.map((reading, index) => {
    const timeDifference = calculateTimeDifferences(
      readings.map((reading) => reading.time.toString())
    )[index];

    return {
      ...reading,
      timeDifference: timeDifference ?? 0, // Use 0 if timeDifference is undefined
    };
  });
}

// Classify glucose levels and accumulate time differences
function classifyReadingByRange(
  reading: PatientCGMEntryDTO & { timeDifference: number },
  maxRange: number,
  minRange: number,
  type: PatientType
) {
  const { glucoseMGPerDL, timeDifference, tag } = reading;
  const increment = timeDifference > 15 ? 15 : timeDifference;

  if (glucoseMGPerDL > 250) return { category: "veryHigh", increment };
  if (glucoseMGPerDL > maxRange && glucoseMGPerDL <= 250)
    return { category: "high", increment };
  if (isGlucosePointInRange(type, glucoseMGPerDL, tag))
    return { category: "inRange", increment };
  if (glucoseMGPerDL >= 54 && glucoseMGPerDL < minRange)
    return { category: "low", increment };
  if (glucoseMGPerDL < 54) return { category: "veryLow", increment };

  return null;
}

// Calculate total percentages for each glucose range category
function calculateAllPercentageValues(
  readings: PatientCGMEntryDTO[],
  maxRange: number,
  minRange: number,
  patientDTO: PatientDTO
) {
  const readingsWithTimeDifference = calculateTimeDifferencesTotal(readings);
  const totals = {
    veryHigh: 0,
    high: 0,
    inRange: 0,
    low: 0,
    veryLow: 0,
  };
  let totalDifference = 0;

  readingsWithTimeDifference.forEach((reading) => {
    const result = classifyReadingByRange(
      reading,
      maxRange,
      minRange,
      patientDTO.type
    );
    if (result) {
      totalDifference += result.increment;

      // Increment the appropriate total based on the category
      switch (result.category) {
        case "veryHigh":
          totals.veryHigh += result.increment;
          break;
        case "high":
          totals.high += result.increment;
          break;
        case "inRange":
          totals.inRange += result.increment;
          break;
        case "low":
          totals.low += result.increment;
          break;
        case "veryLow":
          totals.veryLow += result.increment;
          break;
      }
    }
  });

  /*
   * the conditon totalDifference > 0 is for safety,
   * if the totalDifference is 0, NaN, null or undefined will use 0
   */
  return {
    veryHighPercentageTotal:
      totalDifference > 0
        ? roundTo1DecimalPlace((totals.veryHigh / totalDifference) * 100)
        : 0,
    highPercentageTotal:
      totalDifference > 0
        ? roundTo1DecimalPlace((totals.high / totalDifference) * 100)
        : 0,
    inRangePercentageTotal:
      totalDifference > 0
        ? roundTo1DecimalPlace((totals.inRange / totalDifference) * 100)
        : 0,
    lowPercentageTotal:
      totalDifference > 0
        ? roundTo1DecimalPlace((totals.low / totalDifference) * 100)
        : 0,
    veryLowPercentageTotal:
      totalDifference > 0
        ? roundTo1DecimalPlace((totals.veryLow / totalDifference) * 100)
        : 0,
  };
}

// Aggregate percentages across multiple dates
export function calculateTimeInRangesPercentageValues(
  groupCgmEntriesByDate: GroupCgmEntriesByDate[],
  minRange: number,
  maxRange: number,
  patientDTO: PatientDTO
) {
  const totals = { veryHigh: 0, high: 0, inRange: 0, low: 0, veryLow: 0 };

  groupCgmEntriesByDate.forEach((group) => {
    const percentages = calculateAllPercentageValues(
      group.readings,
      maxRange,
      minRange,
      patientDTO
    );

    totals.veryHigh += percentages.veryHighPercentageTotal;
    totals.high += percentages.highPercentageTotal;
    totals.inRange += percentages.inRangePercentageTotal;
    totals.low += percentages.lowPercentageTotal;
    totals.veryLow += percentages.veryLowPercentageTotal;
  });

  const numGroups = groupCgmEntriesByDate.length;
  return {
    veryHighPercentage: roundTo1DecimalPlace(totals.veryHigh / numGroups),
    highPercentage: roundTo1DecimalPlace(totals.high / numGroups),
    targetPercentage: roundTo1DecimalPlace(totals.inRange / numGroups),
    lowPercentage: roundTo1DecimalPlace(totals.low / numGroups),
    veryLowPercentage: roundTo1DecimalPlace(totals.veryLow / numGroups),
  };
}
