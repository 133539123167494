import { CustomStyles } from "stream-chat-react";
import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  mediumFont,
  regularSemiBoldFont,
  smallMediumFont,
} from "../../../styles/classes/gloabl-classes";
import { lightShadowAlt } from "../../../styles/global-style";

export const customStyles: CustomStyles = {
  "--primary-color": "#00008b",
  "--md-font": "1rem",
  "--xs-m": "1.2rem",
  "--xs-p": "1.2rem",
};

export const ChatHomeBodyContainer = styled.div`
  /* Chat Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100vh;
  overflow: hidden;

  .str-chat__channel {
    width: 100%;
    height: 100vh;
  }

  .str-chat__channel-list.str-chat__channel-list-react
    .str-chat__channel-list-messenger-react
    .str-chat__channel-list-messenger-react__main {
    height: calc(100vh - 210px);
  }

  .str-chat-channel.messaging .str-chat__main-panel {
    padding: 0px;
  }

  .str-chat-channel-list .str-chat__channel-list-messenger__main {
    padding-right: 0px;
    padding-left: 0px;
    background: ${({ theme }) => theme.colors.containerColor};
  }
  .str-chat.messaging,
  .str-chat.commerce {
    background: ${({ theme }) => theme.colors.containerColor};
  }

  .str-chat__channel-list-messenger {
    background: ${({ theme }) => theme.colors.containerColor};
  }

  .str-chat-channel-list.messaging,
  .str-chat-channel-list.team,
  .str-chat-channel-list.commerce {
    @media screen and (max-width: 960px) {
      position: static;
    }
  }
  /* Chat Layout */

  /* date-separator */
  li:has(> .str-chat__date-separator) {
    position: sticky;
    top: 10px;
    z-index: 1;
  }

  .str-chat__date-separator {
    background: ${({ theme }) => theme.newColors.otidaO2};
    border-radius: 16px;
    width: 150px;
    padding: 8px 16px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .str-chat__date-separator hr {
    display: none;
  }

  .str-chat__date-separator-date {
    ${regularSemiBoldFont}
  }
  /* date-separator */

  /* Custom Message Overrides */
  .str-chat__message--me .str-chat__message-text-inner,
  .str-chat__message-simple--me .str-chat__message-text-inner {
    background: ${({ theme }) => theme.newColors.byOtida};
    color: #fff;
  }

  .str-chat__message-text-inner,
  .str-chat__message-text-inner {
    background: #eeeef3;
    border: 0px;
  }

  .str-chat__message-attachment--file {
    background: #eeeef3;
  }

  .str-chat__message-inner audio::-webkit-media-controls-panel {
    background: #eeeef3;
  }

  .audio-sent-by-me audio::-webkit-media-controls-panel {
    background: ${({ theme }) => theme.newColors.byOtida};
  }

  .audio-sent-by-me audio::-webkit-media-controls-mute-button {
    filter: invert(1);
  }

  .audio-sent-by-me audio::-webkit-media-controls-play-button {
    filter: invert(1);
  }

  .audio-sent-by-me audio::-webkit-media-controls-current-time-display {
    filter: invert(1);
  }

  .audio-sent-by-me audio::-webkit-media-controls-time-remaining-display {
    filter: invert(1);
  }

  .audio-sent-by-me audio::-webkit-media-controls-timeline {
    filter: invert(1);
  }

  .audio-sent-by-me audio::-webkit-media-controls-volume-slider {
    filter: invert(1);
  }

  .str-chat__message:has(.audio-sent-by-me)
    > .str-chat__message-inner
    > .str-chat__message-simple__actions,
  .str-chat__message-simple-inner > .str-chat__message-simple__actions {
    left: unset;
    right: calc(100% + 8px);
    flex-direction: row-reverse;
  }

  .str-chat__message:has(.audio-sent-by-me) > .str-chat__avatar {
    margin-right: 0px;
    margin-left: 19px;
  }

  @media screen and (min-width: 420px) {
    .str-chat__message-inner,
    .str-chat__message-simple-inner {
      margin-right: unset;
      margin-left: unset;
    }
  }

  .str-chat__message-simple--me .str-chat__message-attachment--file a,
  .str-chat__message-simple--me .str-chat__message-attachment--file span {
    color: #fff;
  }

  .str-chat__message-simple--me .str-chat__message-attachment--file svg {
    filter: brightness(0%) invert(1);
  }

  .str-chat__message-simple-name {
    display: none;
  }

  .str-chat__message-simple-text-inner.str-chat__message-simple-text-inner--is-emoji {
    padding: 24px;
    min-width: 150px;
    ${flexCenterCenter}
  }

  .str-chat__message-bubble:has(.quoted-message) {
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    min-width: 160px;
  }

  .str-chat__message-text-inner,
  .str-chat__message-simple-text-inner {
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    min-width: 160px;
    position: relative;
  }

  .str-chat__message--me .str-chat__message-text-inner {
    border-radius: 8px 8px 0px 8px;
  }

  .str-chat__message-text-inner {
    border-radius: 8px 8px 8px 0px;
  }

  .str-chat__message-attachment-card--content > * {
    margin-left: auto;
    margin-right: auto;
  }

  .str-chat__message-simple-timestamp {
    ${smallMediumFont}
    color: ${({ theme }) => theme.colors.textColor};
    position: absolute;
    top: -25px;
    right: 19px;
  }

  .str-chat__message-data,
  .str-chat__message-simple-data {
    display: flex;
    align-items: center;
    gap: 0px 8px;
  }

  .str-chat__message-simple-timestamp {
    margin-left: auto;
  }

  .str-chat__message-simple--me .str-chat__message-simple-timestamp {
    color: ${({ theme }) => theme.colors.borderColor};
  }

  .str-chat__message-simple--me .str-chat__message-attachment--file {
    background: ${({ theme }) => theme.newColors.byOtida};
    position: relative;
    padding-bottom: 24px;
    margin-bottom: 0px;
  }

  .str-chat__message--has-attachment .str-chat__message-simple-timestamp {
    position: absolute;
    bottom: 0px;
    right: 19px;
  }

  .custom-message-time {
    color: ${({ theme }) => theme.colors.borderColor};
  }

  .str-chat__li .quoted-message.mine .quoted-message-inner {
    background: ${({ theme }) => theme.colors.primaryColorAlt};
    ${lightShadowAlt}
    position: relative;
    margin-top: 4px;
  }

  .str-chat__li .quoted-message.mine .quoted-message-inner::before {
    content: "";
    height: 100%;
    width: 5px;
    background: ${({ theme }) => theme.colors.primaryColor};
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    border-radius: 4px 0px 0px 4px;
    opacity: 0.7;
  }

  .str-chat__li .quoted-message-inner {
    background: ${({ theme }) => theme.colors.bodyColor};
    ${lightShadowAlt}
    position: relative;
    margin-top: 4px;
  }

  .str-chat__li .quoted-message-inner::before {
    content: "";
    height: 100%;
    width: 5px;
    background: ${({ theme }) => theme.colors.textColor};
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    border-radius: 4px 0px 0px 4px;
  }

  .str-chat__message-text-inner .str-chat__message-simple-text-inner {
    padding-bottom: 12px;
  }

  .str-chat__message-inner:has(.str-chat__message-attachment--file)
    .str-chat__message-attachment--file {
    padding-bottom: 0px;
  }

  .str-chat__message-inner:has(img) .str-chat__message-simple-timestamp {
    bottom: 0px;
  }

  .str-chat__message-inner:has(video) .str-chat__message-simple-timestamp {
    top: -45px;
  }

  .str-chat__message-bubble:has(img) .str-chat__message-simple-text-inner {
    padding: 0px;
  }

  .str-chat__message-bubble:has(img) {
    background: #eeeef3;
    padding: 1px 4px 16px;
    border-radius: 8px;
  }

  .str-chat__message-simple--me .str-chat__message-bubble:has(img) {
    background: ${({ theme }) => theme.newColors.byOtida};
    padding: 4px 4px 16px;
    border-radius: 8px;
  }

  .str-chat__message .str-chat__message-attachment {
    margin-top: 0px;
  }
  /* Custom Message Overrides */

  /* Reactions */
  .str-chat__message--me .str-chat__reaction-list--reverse,
  .str-chat__message--right .str-chat__reaction-list--reverse,
  .str-chat__message-commerce--me .str-chat__reaction-list--reverse,
  .str-chat__message-commerce--right .str-chat__reaction-list--reverse {
    display: none;
  }

  .str-chat__message--me .str-chat__reaction-list--reverse,
  .str-chat__message--right .str-chat__reaction-list--reverse,
  .str-chat__reaction-list--reverse,
  .str-chat__message-commerce--right .str-chat__reaction-list--reverse {
    display: none;
  }

  .str-chat__message--with-reactions,
  .str-chat__message-simple--with-reactions {
    padding-top: 4px;
  }

  .str-chat__li--single {
    padding-bottom: unset;
  }

  .str-chat__li--top,
  .str-chat__li--single {
    padding-top: unset;
  }

  .str-chat__message-reactions-container {
    max-width: 60px;
    margin-left: auto;
  }

  .str-chat__message-inner:has(audio) .str-chat__message-reactions-container {
    margin-left: 0px;
  }

  .str-chat__li--single:has(.str-chat__message--me)
    > .str-chat__message-reactions-container {
    margin-left: auto;
    margin-right: 51px;
    display: flex;
    justify-content: flex-end;
  }

  .str-chat__li--single:has(.str-chat__message--me)
    > .str-chat__message-reactions-container
    ul {
    align-items: center;
  }

  .audio-sent-by-me .str-chat__message-reactions-container {
    margin-left: 250px !important;
  }

  .str-chat__message-inner:has(.hide-reactions)
    .str-chat__message-simple__actions__action--reactions {
    display: none !important;
  }
  /* Reactions */
`;

export const PinnedMessageIcon = styled.div<{ whiteIcon?: boolean }>`
  position: absolute;
  right: ${({ whiteIcon }) => (whiteIcon ? "122px" : "unset")};
  left: ${({ whiteIcon }) => (whiteIcon ? "unset" : "135px")};
  z-index: 3;
  bottom: 7px;

  img {
    width: 15px;
    filter: ${({ whiteIcon }) => (whiteIcon ? "invert(1)" : "invert(0)")};
  }
`;

export const ChannelModalMembersContainer = styled.div`
  margin-top: 16px;
`;

export const ChannelModalAutoSelectContainer = styled.div`
  ${flexCenter}
`;

export const ChannelModalTagList = styled.div`
  ${flexCenter}
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
`;

export const ChannelModalTagListItem = styled.div`
  ${mediumFont}
  color: ${({ theme }) => "#14212e"};
  background: ${({ theme }) => theme.newColors.gray6};
  border-radius: 94px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  width: max-content;
  height: fit-content;
  margin-right: 0.4rem;
  cursor: pointer;

  span {
    font-size: 19px;
  }
`;
