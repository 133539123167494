import { useCallback } from "react";
import { useDeletePatientCGMEntry } from "../../../../../../../hooks/use-delete-patient-cgm-entry";
import { PatientCGMEntryDTO } from "../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { Table } from "../../../../../../../components/table/table";
import { AddNewTableRow } from "./table-rows/add-new-table-row";
import { ManualReadingsTableRow } from "./table-rows/manual-readings-table-row";
import { UpdateTableRow } from "./table-rows/update-table-row";
import useOnReadingsChanged from "../hook/useOnReadingsChanged";
import { useRowsActions } from "../context/rows-actions-context";
import { TH } from "../../../../../../../components/table/styled-table";
import { ManualReadingsTableContainer } from "./styled-manual-readings-table";

type ManualReadingsTableProps = {
  date: string;
};

export const ManualReadingsTable = (props: ManualReadingsTableProps) => {
  const { date } = props;
  const { onReadingsChanged, patientData } = useOnReadingsChanged();

  const deletePatientCGMEntry = useDeletePatientCGMEntry();

  const { cancelOrConfirm, state } = useRowsActions();

  const tableHeadings = ["Time", "Reading", "Tag", "Actions"].map(
    (heading, index) => (
      <TH key={index} modalTableHead actions={heading === "Actions" && true}>
        <div>{heading}</div>
      </TH>
    )
  );

  const cancelAdding = () => {
    cancelOrConfirm();
    tableRows.pop();
  };

  const confirmDeleting = useCallback(
    async (row: PatientCGMEntryDTO) => {
      try {
        await deletePatientCGMEntry(row);
        onReadingsChanged();
      } catch (error) {
        console.error(error);
      }
    },
    [deletePatientCGMEntry, onReadingsChanged]
  );

  const tableRows = patientData
    .filter((filter: any) => {
      if (filter.date === date) {
        return filter;
      } else {
        return null;
      }
    })
    .map((row: PatientCGMEntryDTO, index: number) => {
      if (state.kind === "isEditing" && state.id === index) {
        return (
          <UpdateTableRow
            key={row.id}
            row={row}
            onReadingsChanged={onReadingsChanged}
          />
        );
      } else {
        return (
          <ManualReadingsTableRow
            key={row.id}
            row={row}
            index={index}
            confirmDeleting={confirmDeleting}
          />
        );
      }
    });

  return (
    <ManualReadingsTableContainer>
      <Table
        tableHeadings={tableHeadings}
        tableRows={
          <>
            {tableRows}
            {state.kind === "isAdding" && (
              <AddNewTableRow
                cancelAdding={cancelAdding}
                date={date}
                onReadingsChanged={onReadingsChanged}
              />
            )}
          </>
        }
      />
    </ManualReadingsTableContainer>
  );
};
