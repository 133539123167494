import { useMemo } from "react";
import { calculateTimeInRangesPercentageValues } from "../../utils/time-in-ranges-percentage-utils";
import {
  AGPReportResponseTimeRangesDetailsContainer,
  AGPReportResponseTimeRangesDetailsItem,
} from "./styled-agp-report-response-time-ranges-details";
import { getInRangeNumbers } from "../../../../../../../../utils/patient-type-utils";
import {
  GroupCgmEntriesByDate,
  useAGPReportLoadedResponse,
} from "../../../../context/agp-report-loaded-response-context";

type AGPReportResponseTimeRangesDetailsProps = {
  groupCgmEntriesByDateFilterdByDays: GroupCgmEntriesByDate[];
};

export const AGPReportResponseTimeRangesDetails = (
  props: AGPReportResponseTimeRangesDetailsProps
) => {
  const { groupCgmEntriesByDateFilterdByDays } = props;

  const { patientDTO } = useAGPReportLoadedResponse();

  const inRangeNumber = getInRangeNumbers(patientDTO.type);
  const maxRange = inRangeNumber[1];
  const minRange = inRangeNumber[0];

  const {
    veryHighPercentage,
    highPercentage,
    lowPercentage,
    targetPercentage,
    veryLowPercentage,
  } = useMemo(
    () =>
      calculateTimeInRangesPercentageValues(
        groupCgmEntriesByDateFilterdByDays,
        minRange,
        maxRange,
        patientDTO
      ),
    [groupCgmEntriesByDateFilterdByDays, minRange, maxRange, patientDTO]
  );

  return (
    <AGPReportResponseTimeRangesDetailsContainer>
      <AGPReportResponseTimeRangesDetailsItem
        $height={`${veryHighPercentage}%`}
      >
        <p>
          Very High <span>{`>`}250 mg/dl</span>
        </p>
        <strong>{veryHighPercentage}%</strong>
      </AGPReportResponseTimeRangesDetailsItem>
      <AGPReportResponseTimeRangesDetailsItem $height={`${highPercentage}%`}>
        <p>
          High <span>181 - 250 mg/dl</span>
        </p>
        <strong>{highPercentage}%</strong>
      </AGPReportResponseTimeRangesDetailsItem>
      <AGPReportResponseTimeRangesDetailsItem $height={`${targetPercentage}%`}>
        <p>
          Target <span>70 - 180 mg/dl</span>
        </p>
        <strong>{targetPercentage}%</strong>
      </AGPReportResponseTimeRangesDetailsItem>
      <AGPReportResponseTimeRangesDetailsItem $height={`${lowPercentage}%`}>
        <p>
          Low <span>54 - 69 mg/dl</span>
        </p>
        <strong>{lowPercentage}%</strong>
      </AGPReportResponseTimeRangesDetailsItem>
      <AGPReportResponseTimeRangesDetailsItem $height={`${veryLowPercentage}%`}>
        <p>
          Very Low <span>{`<`} 54</span>
        </p>
        <strong>{veryLowPercentage}%</strong>
      </AGPReportResponseTimeRangesDetailsItem>
    </AGPReportResponseTimeRangesDetailsContainer>
  );
};
