import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  lgMediumFont,
  xlSemiBoldFont,
} from "../../styles/classes/gloabl-classes";

export const ScreenHeaderContainer = styled.div`
  padding: 8px 16px 0px;
  background: ${({ theme }) => theme.colors.containerColor};

  @media screen and (max-width: 650px) {
    padding: ${({ theme }) => theme.padding.px_05};
  }
`;

export const HeaderTitle = styled.h1`
  ${xlSemiBoldFont}
  color: ${({ theme }) => theme.colors.screenTitleColor};

  @media screen and (max-width: 710px) {
    ${lgMediumFont}
  }
`;

export const HeaderTopContainer = styled.div`
  ${flexCenterSpaceBetween}

  h3 {
    margin: 0px;
  }

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

export const ButtonsContainer = styled.div<{ flexCol?: boolean }>`
  ${flexCenter}
  gap: 0rem 1rem;

  @media screen and (max-width: 650px) {
    justify-content: space-between;
    width: 100%;
    gap: 0rem;
    margin-bottom: ${({ theme }) => theme.margin.mr_b_1};
  }

  @media screen and (max-width: 425px) {
    ${({ flexCol }) =>
      flexCol
        ? `
      flex-direction: column-reverse;
      gap: 1rem 0rem;
    `
        : ``};
  }
`;
