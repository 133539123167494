import { useMemo } from "react";
import {
  AGPReportResponseTimeRangesChartContainer,
  AGPReportResponseTimeRangesChartItem,
} from "./styled-agp-report-response-time-ranges-chart";
import { calculateTimeInRangesPercentageValues } from "../../utils/time-in-ranges-percentage-utils";
import { getInRangeNumbers } from "../../../../../../../../utils/patient-type-utils";
import {
  GroupCgmEntriesByDate,
  useAGPReportLoadedResponse,
} from "../../../../context/agp-report-loaded-response-context";

type AGPReportResponseTimeRangesChartProps = {
  groupCgmEntriesByDateFilterdByDays: GroupCgmEntriesByDate[];
};

export const AGPReportResponseTimeRangesChart = (
  props: AGPReportResponseTimeRangesChartProps
) => {
  const { groupCgmEntriesByDateFilterdByDays } = props;

  const { patientDTO } = useAGPReportLoadedResponse();

  const inRangeNumber = getInRangeNumbers(patientDTO.type);
  const maxRange = inRangeNumber[1];
  const minRange = inRangeNumber[0];

  const {
    veryHighPercentage,
    highPercentage,
    lowPercentage,
    targetPercentage,
    veryLowPercentage,
  } = useMemo(
    () =>
      calculateTimeInRangesPercentageValues(
        groupCgmEntriesByDateFilterdByDays,
        minRange,
        maxRange,
        patientDTO
      ),
    [groupCgmEntriesByDateFilterdByDays, minRange, maxRange, patientDTO]
  );

  return (
    <AGPReportResponseTimeRangesChartContainer>
      <AGPReportResponseTimeRangesChartItem
        className="very-high"
        $height={`${veryHighPercentage}%`}
      />
      <AGPReportResponseTimeRangesChartItem
        className="high"
        $height={`${highPercentage}%`}
      />
      <AGPReportResponseTimeRangesChartItem
        className="target"
        $height={`${targetPercentage}%`}
      />
      <AGPReportResponseTimeRangesChartItem
        className="low"
        $height={`${lowPercentage}%`}
      />
      <AGPReportResponseTimeRangesChartItem
        className="very-low"
        $height={`${veryLowPercentage}%`}
      />
    </AGPReportResponseTimeRangesChartContainer>
  );
};
