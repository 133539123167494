import styled from "styled-components/macro";
import { flexCenter } from "../../styles/classes/gloabl-classes";
import { DropdownListItem } from "../dropdown/styled-dropdown";
import { Button } from "../../styles/classes/reusable-classes";

export const DateRangeContainer = styled.div`
  ${flexCenter}
  gap: 24px;
  position: relative;

  ${DropdownListItem} {
    max-height: 41px;
  }

  .clear-dates-button {
    position: absolute;
    right: 20px;
    top: 30px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 20px;
  }

  ${Button} {
    width: 150px;
    height: 40px;
    font-size: 14px;
    background: #f4f5f7;
    color: #000;
    padding: 8px 16px;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
