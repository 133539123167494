import { ChangeEvent, useCallback } from "react";
import {
  DaysFilter,
  useAGPDaysFilter,
} from "../../../../agp-report/context/agp-days-filter-context";
import {
  AgpReportResponseDaysFilterCheckbox,
  AgpReportResponseDaysFilterCheckboxesContainer,
  AgpReportResponseDaysFilterCheckboxWrapper,
  AgpReportResponseDaysFilterCheckMark,
} from "./styled-agp-report-response-days-filter-checkboxes";

export const AgpReportResponseDaysFilterCheckboxes = () => {
  const { daysFilter, setDaysFilter } = useAGPDaysFilter();

  const weekDays: DaysFilter[] = buildWeekDays();
  const weekEnds: DaysFilter[] = buildWeekEnds();

  const hasWeekDays = weekDays.every((weekDay) =>
    daysFilter.some(
      (filter) =>
        filter.filterId === weekDay.filterId &&
        filter.filterName === weekDay.filterName
    )
  );

  const hasWeekEnds = weekEnds.every((weekDay) =>
    daysFilter.some(
      (filter) =>
        filter.filterId === weekDay.filterId &&
        filter.filterName === weekDay.filterName
    )
  );

  const checkedStateHandler = useCallback(
    (state: "WeekDays" | "WeekEnds") => {
      if (state === "WeekDays") {
        setDaysFilter([...daysFilter, ...weekDays]);
      } else {
        setDaysFilter([...daysFilter, ...weekEnds]);
      }
    },
    [daysFilter, setDaysFilter, weekDays, weekEnds]
  );

  const handleWeekDaysOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        checkedStateHandler("WeekDays");
      } else {
        setDaysFilter(
          daysFilter.filter(
            (item) =>
              item.filterName === "Fridays" || item.filterName === "Saturdays"
          )
        );
      }
    },
    [checkedStateHandler, daysFilter, setDaysFilter]
  );

  const handleWeekEndsOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        checkedStateHandler("WeekEnds");
      } else {
        setDaysFilter(
          daysFilter.filter(
            (item) =>
              item.filterName !== "Fridays" && item.filterName !== "Saturdays"
          )
        );
      }
    },
    [checkedStateHandler, daysFilter, setDaysFilter]
  );

  return (
    <AgpReportResponseDaysFilterCheckboxesContainer>
      <AgpReportResponseDaysFilterCheckboxWrapper className="weekdays-checkbox">
        Weekdays
        <AgpReportResponseDaysFilterCheckbox
          id="week-days"
          type="checkbox"
          checked={hasWeekDays}
          onChange={(e) => handleWeekDaysOnChange(e)}
        />
        <AgpReportResponseDaysFilterCheckMark></AgpReportResponseDaysFilterCheckMark>
      </AgpReportResponseDaysFilterCheckboxWrapper>
      <AgpReportResponseDaysFilterCheckboxWrapper className="weekends-checkbox">
        Weekends
        <AgpReportResponseDaysFilterCheckbox
          id="week-ends"
          type="checkbox"
          checked={hasWeekEnds}
          onChange={(e) => handleWeekEndsOnChange(e)}
        />
        <AgpReportResponseDaysFilterCheckMark></AgpReportResponseDaysFilterCheckMark>
      </AgpReportResponseDaysFilterCheckboxWrapper>
    </AgpReportResponseDaysFilterCheckboxesContainer>
  );
};

function buildWeekDays(): DaysFilter[] {
  return [
    { filterId: 0, filterName: "Sundays" },
    { filterId: 1, filterName: "Mondays" },
    { filterId: 2, filterName: "Tuesdays" },
    { filterId: 3, filterName: "Wednesdays" },
    { filterId: 4, filterName: "Thursdays" },
  ];
}

function buildWeekEnds(): DaysFilter[] {
  return [
    { filterId: 5, filterName: "Fridays" },
    { filterId: 6, filterName: "Saturdays" },
  ];
}
