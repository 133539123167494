import { createContext, PropsWithChildren, useContext, useState } from "react";

type AgpOrOverlayState = "AGP" | "Overlay";

type AgpOrOverlay = {
  switchAgp: AgpOrOverlayState;
  setSwitchAgp: (switchAgp: AgpOrOverlayState) => void;
};

const AgpOrOverlayContext = createContext<AgpOrOverlay | undefined>(undefined);

type AgpOrOverlayProviderProps = PropsWithChildren<{}>;

export function AgpOrOverlayProvider(props: AgpOrOverlayProviderProps) {
  const { children } = props;

  const [switchAgp, setSwitchAgp] = useState<AgpOrOverlayState>("AGP");

  return (
    <AgpOrOverlayContext.Provider
      value={{
        switchAgp,
        setSwitchAgp,
      }}
    >
      {children}
    </AgpOrOverlayContext.Provider>
  );
}

export function useAgpOrOverlay() {
  const context = useContext(AgpOrOverlayContext);

  if (!context) {
    throw new Error(
      "useAgpOrOverlay must be used within a AgpOrOverlayProvider"
    );
  }

  return context;
}
