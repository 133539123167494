export const BASE_URL: string = process.env.REACT_APP_BASE_URL!;

export const GOOGLE_CLIENT_ID: string = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

export const AUTH0_DOMAIN: string = process.env.REACT_APP_AUTH0_DOMAIN!;
export const AUTH0_CLIENT_ID: string = process.env.REACT_APP_AUTH0_CLIENT_ID!;

export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN!;
export const SENTRY_ENVIRONMENT: string = process.env
  .REACT_APP_SENTRY_ENVIRONMENT!;

export const GAP_THRESHOLD_MINUTES: number = parseInt(
  process.env.REACT_APP_GAP_THRESHOLD_MINUTES!
);

export const MEAL_GLUCOSE_POINT: number = parseInt(
  process.env.REACT_APP_MEAL_GLUCOSE_POINT!
);

export const MEDICINE_GLUCOSE_POINT: number = parseInt(
  process.env.REACT_APP_MEDICINE_GLUCOSE_POINT!
);

export const STREAM_CHAT_API_KEY = process.env.REACT_APP_STREAM_CHAT_API_KEY!;

export const FEATURE_FLAG_DEVELOPMENT_MODAL: boolean =
  process.env.REACT_APP_DEVELOPMENT_MODAL === "true";

export const FEATURE_FLAG_NUTRITION_SUMMARY_FOR_DEMO: boolean =
  process.env.REACT_APP_NUTRITION_SUMMARY_FOR_DEMO === "true";

export const FEATURE_FLAG_FRONTEND_DEMO: boolean =
  process.env.REACT_APP_FRONTEND_DEMO === "true";

export const FEATURE_FLAG_LOGIN_WITH_EMAIL_AND_PASSWORD: boolean =
  process.env.REACT_APP_LOGIN_WITH_EMAIL_AND_PASSWORD === "true";

export const FEATURE_FLAG_NUTRITION_SUMMARY_PIE_CHART: boolean =
  process.env.REACT_APP_NUTRITION_SUMMARY_PIE_CHART === "true";

export const FEATURE_FLAG_NUTRITION_GOALS: boolean =
  process.env.REACT_APP_NUTRITION_GOALS === "true";

export const FEATURE_FLAG_NUTRITION_REPORT: boolean =
  process.env.REACT_APP_NUTRITION_REPORT === "true";

export const FEATURE_FLAG_CHAT: boolean = process.env.REACT_APP_CHAT === "true";

export const FEATURE_FLAG_ORGANIZATIONS_AND_ROLES: boolean =
  process.env.REACT_APP_ORGANIZATIONS_AND_ROLES === "true";

export const FEATURE_FLAG_404_NOT_FOUND: boolean =
  process.env.REACT_APP_404_NOT_FOUND === "true";

export const FEATURE_FLAG_REPLACE_GOOGLE_CHART_WITH_SYNCFUSION: boolean =
  process.env.REACT_APP_REPLACE_GOOGLE_CHART_WITH_SYNCFUSION === "true";

export const FEATURE_FLAG_FOOD_LOGGING_NEW_UI: boolean =
  process.env.REACT_APP_FOOD_LOGGING_NEW_UI === "true";

export const FEATURE_FLAG_CGM_REPORT: boolean =
  process.env.REACT_APP_CGM_REPORT === "true";

export const FEATURE_FLAG_CONTENT_MODULES: boolean =
  process.env.REACT_APP_CONTENT_MODULES === "true";

export const FEATURE_FLAG_HOME: boolean = process.env.REACT_APP_HOME === "true";

export const FEATURE_FLAG_DAILY_ANALYSIS: boolean =
  process.env.REACT_APP_DAILY_ANALYSIS === "true";

export const FEATURE_FLAG_BGM_SUBTAB: boolean =
  process.env.REACT_APP_BGM_SUBTAB === "true";

export const FEATURE_FLAG_DAILY_FIXED_SUMMARY: boolean =
  process.env.REACT_APP_DAILY_FIXED_SUMMARY === "true";

export const FEATURE_FLAG_APEX_CHART: boolean =
  process.env.REACT_APP_APEX_CHART === "true";

export const FEATURE_FLAG_NUTRITION_SUMMARY_FROM_MEALS: boolean =
  process.env.REACT_APP_NUTRITION_SUMMARY_FROM_MEALS === "true";

export const FEATURE_FLAG_COMPARE: boolean =
  process.env.REACT_APP_COMPARE === "true";
