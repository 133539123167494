import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  flexColCenterCenter,
  mediumFont,
  mediumSemiBoldFont,
} from "../../../../styles/classes/gloabl-classes";
import { lightShadowAlt } from "../../../../styles/global-style";

export const CgmTooltipContainer = styled.div`
  pointer-events: auto;
  background: ${({ theme }) => theme.colors.containerColor};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  width: 300px;
  display: flex;
  flex-direction: column;
  ${lightShadowAlt}
`;

export const CgmTooltipHeader = styled.div`
  background: ${({ theme }) => theme.newColors.otidaO2};
  ${flexCenterSpaceBetween}
  padding: 16px;

  p {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.titleColor};
    display: grid;
    margin: 0px;
  }

  span {
    font-size: 12px;
    margin-left: 0px;
    font-weight: 700;
    color: ${({ theme }) => theme.newColors.otida03};
  }
`;

export const CgmPatientMealsMacros = styled.div`
  padding: 0.5rem 0.8rem 0rem;
`;

export const CgmPatientMealsMacrosTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.screenTitleColor};
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.newColors.modalContainerAlt};
    font-weight: 600;
  }
`;

export const CgmPatientMealsMacrosCenter = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 6px;
  background: ${({ theme }) => theme.newColors.otidaO2};
  margin: 0.5rem 0rem;
  display: flex;
  align-items: center;
  position: relative;
`;

export const CgmPatientMealsMacrosBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0rem 1rem;
  margin-top: 1rem;
  padding: 0rem 1rem;
`;

export const Carbs = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.newColors.warningAlt};
  position: absolute;
  z-index: 3;
`;

export const Fat = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.primaryColor};
  position: absolute;
  z-index: 2;
`;

export const Protien = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  left: 0;
  height: 100%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.primaryColorAlt};
  position: absolute;
  z-index: 1;
`;

export const CgmTooltipBody = styled.div`
  padding: 0.5rem 1rem;

  button {
    margin-left: auto;
  }
`;

export const CgmTooltipImageContainer = styled.div`
  max-height: 150px;
  width: 100%;
  margin: 0 auto;
  ${flexColCenterCenter}
  overflow: hidden;

  img {
    width: 100%;
    border-radius: 6px;
    height: 150px;
    margin: 16px auto 0px;
    cursor: pointer;
    object-fit: cover;
    object-position: top center;
  }
`;

export const CGMTooltipMealIngredentContainer = styled.div`
  ${flexCenterSpaceBetween}
  margin: 8px;

  p {
    ${mediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 150px;

    :hover {
      white-space: normal;
      overflow: visible;
    }
  }

  span {
    ${mediumSemiBoldFont}
    color: ${({ theme }) => theme.newColors.textColorAlt};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    :hover {
      white-space: normal;
      overflow: visible;
    }
  }
`;
