import { DateRangeProvider } from "../../../../components/date-range/context/date-range-context";
import { AGPReportResponse } from "./agp-report-response/agp-report-response";

export const AGPReport = () => {
  return (
    <DateRangeProvider>
      <AGPReportResponse />
    </DateRangeProvider>
  );
};
