import { DatesWithinRange } from "../../../../../../components/date-range/context/date-range-context";
import { DaysFilter } from "../../context/agp-days-filter-context";
import { GroupCgmEntriesByDate } from "../../context/agp-report-loaded-response-context";

export function filterdByDaysGroupCgmEntriesByDate(
  groupCgmEntriesByDate: GroupCgmEntriesByDate[],
  daysFilter: DaysFilter[],
  datesWithinRange: DatesWithinRange[]
) {
  if (daysFilter.length === 0) {
    return groupCgmEntriesByDate;
  } else {
    const dayFilter = daysFilter.map((item) =>
      item.filterName.slice(0, item.filterName.length - 1)
    );
    const datesToFilter = datesWithinRange
      .filter((date) => dayFilter.includes(date.weekDay))
      .map((item) => item.date);

    return groupCgmEntriesByDate.filter((entry) =>
      datesToFilter.includes(entry.date)
    );
  }
}
