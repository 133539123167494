import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  regularSemiBoldFont,
} from "../../../../../../styles/classes/gloabl-classes";

export const NutritionAnalysisResponseHeaderContainer = styled.div`
  ${flexCenterSpaceBetween}
  padding: 8px 24px;

  p {
    ${regularSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
  }
`;

export const NutritionAnalysisResponseHeaderMacrosContainer = styled.div`
  display: grid;
  gap: 6px 0px;
  margin: 0 auto;
`;

export const NutritionAnalysisResponseHeaderMacroItem = styled.div`
  ${flexCenter}
  gap: 0px 12px;
  position: relative;

  ::before {
    content: "";
    width: 10px;
    height: 10px;
  }

  :nth-child(1) {
    ::before {
      background-color: #febb12;
    }
  }

  :nth-child(2) {
    ::before {
      background-color: #00086b;
    }
  }

  :nth-child(3) {
    ::before {
      background-color: #1097cf;
    }
  }

  p {
    width: 170px;

    span {
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;
