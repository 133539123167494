import styled from "styled-components/macro";

export const AGPReportResponseTimeRangesChartContainer = styled.div`
  width: 70px;
  height: 180px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  position: relative;
  overflow: hidden;
`;

export const AGPReportResponseTimeRangesChartItem = styled.div<{
  $height: string;
}>`
  height: ${({ $height }) => $height};
  width: 100%;
  min-height: 2%;

  :nth-child(1) {
    background: ${({ theme }) => theme.colors.warning};
    z-index: 5;
  }

  :nth-child(2) {
    background: #f1d98b;
    z-index: 4;
  }

  :nth-child(3) {
    background: ${({ theme }) => theme.colors.success};
    z-index: 3;
  }

  :nth-child(4) {
    background: ${({ theme }) => theme.colors.danger};
    z-index: 2;
  }

  :nth-child(5) {
    background: #6d2f33;
    z-index: 1;
  }
`;
