import { useMemo } from "react";
import {
  AGPReportResponseGlucoseStatisticsBody,
  AGPReportResponseGlucoseStatisticsBodyItem,
  AGPReportResponseGlucoseStatisticsBodyItemTitle,
  AGPReportResponseGlucoseStatisticsContainer,
  AGPReportResponseGlucoseStatisticsHeader,
} from "./styled-agp-report-response-glucose-statistics";
import { roundTo1DecimalPlace } from "../../../../../../../utils/math-utils";
import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { useDateRange } from "../../../../../../../components/date-range/context/date-range-context";
import { calculateReadingsTimeDiffrenceTotal } from "../../../../../../../utils/glucose-point-utils/calculate-readings-time-differance-total";
import { buildDaysAggregatesReadingsWithTimeDifference } from "../../../../../../../utils/glucose-point-utils/build-readings-with-time-differance-value";
import { convertCGMEntriesToReadings } from "../../../../../../../utils/glucose-point-utils/convert-cgm-entries-to-readings";
import { filterPatientCgmEntriesByWeekday } from "../../utils/filter-patient-cgm-entries-by-week-day";
import { useAGPReportLoadedResponse } from "../../../context/agp-report-loaded-response-context";
import { useAGPDaysFilter } from "../../../context/agp-days-filter-context";

export const AGPReportResponseGlucoseStatistics = () => {
  const {
    patientCGMEntryDTOs,
    groupCgmEntriesByDate,
  } = useAGPReportLoadedResponse();

  const { selectedDates } = useDateRange();

  const { daysFilter } = useAGPDaysFilter();

  const datesWithinRange = selectedDates.datesWithinRange;

  const fitlerPatientCGMEntryDTOsByDay = useMemo(
    () =>
      filterPatientCgmEntriesByWeekday(
        patientCGMEntryDTOs,
        daysFilter,
        datesWithinRange
      ),
    [daysFilter, patientCGMEntryDTOs, datesWithinRange]
  );

  const { average } = useMemo(() => {
    let total = 0;

    for (const patientCGMEntryDTO of fitlerPatientCGMEntryDTOsByDay) {
      const glucoseReading = patientCGMEntryDTO.glucoseMGPerDL;

      total += glucoseReading;
    }

    const average = roundTo1DecimalPlace(
      total / fitlerPatientCGMEntryDTOsByDay.length
    );

    return { average };
  }, [fitlerPatientCGMEntryDTOsByDay]);

  const { gmi, warningGMIMessage } = useMemo(() => {
    let warningGMIMessage: string = "";

    // 3.31 + 0.02392 * average
    const glycatedAlbumin = 3.31;
    const glycatedProteins = 0.02392;

    if (groupCgmEntriesByDate.length < 14 || selectedDates.count < 14) {
      warningGMIMessage =
        "Can't be calculated, date range is less than 14 days!";
    }

    const glycatedProteinsByAverage = glycatedProteins * average;
    const gmi = glycatedAlbumin + glycatedProteinsByAverage;

    return { gmi, warningGMIMessage };
  }, [selectedDates.count, average, groupCgmEntriesByDate]);

  const { cgmActivePercentage } = useMemo(() => {
    const cgmReadingsInSelectedDateRange = groupCgmEntriesByDate
      .map((item) => item.readings)
      .flat();

    const convertCgmReadingsInSelectedDateRangeToReadings = convertCGMEntriesToReadings(
      cgmReadingsInSelectedDateRange
    );

    const readingsWithTimeDifferenceValue = buildDaysAggregatesReadingsWithTimeDifference(
      convertCgmReadingsInSelectedDateRangeToReadings
    );

    const readingsTimeDifferanceTotal = calculateReadingsTimeDiffrenceTotal(
      readingsWithTimeDifferenceValue
    );

    const totalHoursInSelectedDates = selectedDates.count * 24;
    const totalMinutesInSelectedDates = totalHoursInSelectedDates * 60;

    const cgmActivePercentage = roundTo1DecimalPlace(
      (readingsTimeDifferanceTotal / totalMinutesInSelectedDates) * 100
    );

    return { cgmActivePercentage };
  }, [groupCgmEntriesByDate, selectedDates.count]);

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <AGPReportResponseGlucoseStatisticsContainer>
        <AGPReportResponseGlucoseStatisticsHeader>
          <p>Glucose Statistics</p>
        </AGPReportResponseGlucoseStatisticsHeader>
        <AGPReportResponseGlucoseStatisticsBody>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </AGPReportResponseGlucoseStatisticsBody>
      </AGPReportResponseGlucoseStatisticsContainer>
    );
  }

  return (
    <AGPReportResponseGlucoseStatisticsContainer>
      <AGPReportResponseGlucoseStatisticsHeader>
        <p>Glucose Statistics</p>
      </AGPReportResponseGlucoseStatisticsHeader>
      <AGPReportResponseGlucoseStatisticsBody>
        <AGPReportResponseGlucoseStatisticsBodyItem $topListItem>
          <div>
            <AGPReportResponseGlucoseStatisticsBodyItemTitle>
              Average Glucose
            </AGPReportResponseGlucoseStatisticsBodyItemTitle>
            <span>
              {average} <span>mg/dl</span>
            </span>
          </div>
          <div>
            <AGPReportResponseGlucoseStatisticsBodyItemTitle>
              % Time CGM is Active:
            </AGPReportResponseGlucoseStatisticsBodyItemTitle>
            <span>{cgmActivePercentage}%</span>
          </div>
        </AGPReportResponseGlucoseStatisticsBodyItem>
        <AGPReportResponseGlucoseStatisticsBodyItem>
          <AGPReportResponseGlucoseStatisticsBodyItemTitle>
            Glucose Management Indicator (GMI)
            <span>
              Approximate A1C level based in average CGM Glucose level.
            </span>
          </AGPReportResponseGlucoseStatisticsBodyItemTitle>
          {selectedDates.count < 14 || groupCgmEntriesByDate.length < 14 ? (
            <strong>{warningGMIMessage}</strong>
          ) : (
            <span>{roundTo1DecimalPlace(gmi)}%</span>
          )}
        </AGPReportResponseGlucoseStatisticsBodyItem>
      </AGPReportResponseGlucoseStatisticsBody>
    </AGPReportResponseGlucoseStatisticsContainer>
  );
};
