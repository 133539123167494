import styled from "styled-components/macro";

export const AGPReportResponseHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    gap: 8px;
  }
`;
