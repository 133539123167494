import styled from "styled-components/macro";
import { DateRangeContainer } from "../../../../../components/date-range/styled-date-range";

export const LoadedDailyChartsContainer = styled.div`
  ${DateRangeContainer} {
    @media screen and (max-width: 1440px) {
      flex-direction: column;
    }
  }
`;

export const DailyChartPaginationContainer = styled.div`
  padding: 8px 32px 0px;
`;
