import { AGPReportResponseHeaderContainer } from "./styled-agp-report-response-header";
import { DateRange } from "../../../../../../components/date-range/date-range";

export const AGPReportResponseHeader = () => {
  return (
    <AGPReportResponseHeaderContainer>
      <DateRange />
    </AGPReportResponseHeaderContainer>
  );
};
