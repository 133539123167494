import { PatientType } from "../../../../../../../../models/patient-dtos/patient-type";
import { PatientCGMEntryDTOTimeDifference } from "../types/patient-cgm-entry-with-time-difference";
import { isGlucosePointAboveRange } from "./glucose-ranges/is-glucose-point-above-range";
import { isGlucosePointBelowRange } from "./glucose-ranges/is-glucose-point-below-range";
import { isGlucosePointInRange } from "./glucose-ranges/is-glucose-point-in-range";

export function calculateRanges(
  patientType: PatientType,
  readingsWithTimeDifferenceValue: PatientCGMEntryDTOTimeDifference[]
) {
  let belowRangeReadingsTimeDiffrenceTotal: number = 0;
  let aboveRangeReadingsTimeDiffrenceTotal: number = 0;
  let inRangeReadingsTimeDiffrenceTotal: number = 0;

  for (const readingWithTimeDifferenceValue of readingsWithTimeDifferenceValue) {
    if (readingWithTimeDifferenceValue.timeDifference !== undefined) {
      if (
        isGlucosePointBelowRange(readingWithTimeDifferenceValue.glucoseMGPerDL)
      ) {
        if (readingWithTimeDifferenceValue.timeDifference > 15) {
          belowRangeReadingsTimeDiffrenceTotal += 15;
        } else {
          belowRangeReadingsTimeDiffrenceTotal +=
            readingWithTimeDifferenceValue.timeDifference;
        }
      }
      if (
        isGlucosePointAboveRange(
          patientType,
          readingWithTimeDifferenceValue.glucoseMGPerDL,
          readingWithTimeDifferenceValue.tag
        )
      ) {
        if (readingWithTimeDifferenceValue.timeDifference > 15) {
          aboveRangeReadingsTimeDiffrenceTotal += 15;
        } else {
          aboveRangeReadingsTimeDiffrenceTotal +=
            readingWithTimeDifferenceValue.timeDifference;
        }
      }
      if (
        isGlucosePointInRange(
          patientType,
          readingWithTimeDifferenceValue.glucoseMGPerDL,
          readingWithTimeDifferenceValue.tag
        )
      ) {
        if (readingWithTimeDifferenceValue.timeDifference > 15) {
          inRangeReadingsTimeDiffrenceTotal += 15;
        } else {
          inRangeReadingsTimeDiffrenceTotal +=
            readingWithTimeDifferenceValue.timeDifference;
        }
      }
    }
  }

  return {
    belowRangeReadingsTimeDiffrenceTotal,
    aboveRangeReadingsTimeDiffrenceTotal,
    inRangeReadingsTimeDiffrenceTotal,
  };
}
