import {
  CircleCharts,
  ChartCard,
  ChartCardHeader,
  ChartCardTitle,
  ChartContainer,
  DailyChartsContainer,
  DailyChartsDate,
  LineChartContainer,
  ChartContainerLegend,
  ChartLegendPoint,
} from "./styled-daily-chart";
import { DayAggregate } from "../../../../../../components/cgm-google-chart/utils/build-days-aggregates";
import { useBglAnalysis } from "../../../context/loadable-bgl-analysis-context";
import { CgmSyncfusionChart } from "../../../../../../components/cgm-syncfusion-chart/cgm-syncfusion-chart";
import { NutritionSummaryFromMeals } from "./nutrition-summary-from-meals/nutrition-summary-from-meals";
import { DailyChartRanges } from "./daily-chart-ranges/daily-chart-ranges";

export type DailyChartProps = {
  dayAggregate: DayAggregate;
  zIndex: number;
};

export function DailyChart(props: DailyChartProps) {
  const { dayAggregate, zIndex } = props;

  const { patientDTO } = useBglAnalysis();

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const displayDate = `${dayAggregate.date.toLocaleString(
    "en-US",
    dateOptions
  )}`;

  if (dayAggregate.date.toString() === "2024-05-21" && patientDTO.id === 130) {
    return <></>;
  }

  return (
    <ChartCard>
      <DailyChartsDate>
        <span className="material-symbols-outlined">calendar_today</span>
        <p>{displayDate}</p>
      </DailyChartsDate>

      <DailyChartsContainer>
        <ChartContainer>
          <ChartCardHeader>
            <ChartCardTitle>
              <p>Daily graph</p>
              <span>Blood glucose reading</span>
            </ChartCardTitle>
            <CircleCharts>
              {dayAggregate.patientCGMEntryDTOs.length > 0 && (
                <DailyChartRanges
                  smallCircles={false}
                  dayAggregate={dayAggregate}
                  patientDTO={patientDTO}
                />
              )}
            </CircleCharts>
          </ChartCardHeader>
          <LineChartContainer>
            <CgmSyncfusionChart
              patientDTO={patientDTO}
              dayAggregate={dayAggregate}
              zindex={zIndex}
              hasTooltip
              enableLegend={false}
            />
          </LineChartContainer>
          <ChartContainerLegend>
            <ChartLegendPoint>
              <img src="/img/meals.svg" alt="meals" />
              <span>Meals</span>
            </ChartLegendPoint>
            <ChartLegendPoint medic>
              <img src="/img/medications.svg" alt="medications" />
              <span>Medications</span>
            </ChartLegendPoint>
            <ChartLegendPoint manual>
              <img src="/img/readings.svg" alt="readings" />
              <span>Manual Reading</span>
            </ChartLegendPoint>
            <ChartLegendPoint activity>
              <img src="/img/activity.svg" alt="activity" />
              <span>Activity</span>
            </ChartLegendPoint>
            <ChartLegendPoint insulin>
              <img src="/img/insulin.svg" alt="insulin" />
              <span>Insulin</span>
            </ChartLegendPoint>
          </ChartContainerLegend>
        </ChartContainer>
        <NutritionSummaryFromMeals
          patientMealDTOs={dayAggregate.patientMealDTO}
        />
      </DailyChartsContainer>
    </ChartCard>
  );
}
