import styled from "styled-components/macro";
import { TableContainer } from "../../../../../../../components/table/styled-table";

export const ManualReadingsTableContainer = styled.div`
  height: 100%;

  ${TableContainer} {
    height: 100%;

    table {
      thead {
        th {
          :nth-child(1) {
            width: 30%;
          }
          :nth-child(2) {
            width: 30%;
          }
          :nth-child(3) {
            width: 30%;
          }
        }
      }
    }
  }
`;
