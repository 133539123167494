import { MarkerSettingsModel } from "@syncfusion/ej2-react-charts";
import { InsulinSeries } from "../../series/build-insulin-series";
import { MarkerTemplateInsulinContainer } from "./styled-marker-template";

export function buildInsulinMarkerTemplate(): MarkerSettingsModel {
  const template: any = markerTemplate;

  return {
    visible: true,
    border: { width: 0 },
    shape: "Image",
    imageUrl: "/img/insulin.svg",
    width: 20,
    height: 20,
    dataLabel: {
      visible: true,
      template: template,
    },
  };
}

function markerTemplate(args: any) {
  const dataSource: InsulinSeries[] = args.series.properties.dataSource;

  const findCurrentDataSource = dataSource.find(
    (item) => item.y === args.point.y
  );

  if (
    findCurrentDataSource &&
    findCurrentDataSource.tooltip.kind.kind === "Insulin"
  ) {
    const insulinUnits = findCurrentDataSource.patientInsulinLogDTO.dose;

    return (
      <MarkerTemplateInsulinContainer>
        <p>
          {insulinUnits} <span>U</span>
        </p>
      </MarkerTemplateInsulinContainer>
    );
  }

  return <></>;
}
