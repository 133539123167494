export function generateDateRange(startDate: Date, endDate: Date) {
  const dateRange: Date[] = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dateRange.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return {
    dates: dateRange,
    numberOfDays: dateRange.length - 1,
  };
}
