import {
  AGPReportResponseDaysFilterContainer,
  AGPReportResponseDaysFilterList,
  AGPReportResponseDaysFilterListItem,
} from "./styled-agp-report-response-days-filter";
import {
  DaysFilterOptions,
  useAGPDaysFilter,
} from "../../../agp-report/context/agp-days-filter-context";
import { useAgpOrOverlay } from "../../context/agp-or-overlay-context";
import { AgpReportResponseDaysFilterCheckboxes } from "./agp-report-response-days-filter-checkboxes/agp-report-response-days-filter-checkboxes";
import { AGPReportResponseMiniAGPProvider } from "./agp-report-response-mini-agp/agp-report-response-mini-agp-context";
import { AGPReportResponseMiniAGP } from "./agp-report-response-mini-agp/agp-report-response-mini-agp";
import { AgpReportResponseMiniOverlay } from "./agp-report-response-mini-overlay/agp-report-response-mini-overlay";
import { useCallback } from "react";

export const AGPReportResponseDaysFilter = () => {
  const {
    countHowManyDaysWithinRange,
    daysFilter,
    setDaysFilter,
  } = useAGPDaysFilter();
  const { switchAgp } = useAgpOrOverlay();

  const filterOptions: FilterOption[] = buildFilterOptions(
    countHowManyDaysWithinRange
  );

  const handleFilters = useCallback(
    (filter: FilterOption, index: number) => {
      if (daysFilter.some((item) => item.filterId === index)) {
        setDaysFilter(daysFilter.filter((item) => item.filterId !== index));
      } else {
        setDaysFilter([
          ...daysFilter,
          { filterId: index, filterName: filter.value },
        ]);
      }
    },
    [daysFilter, setDaysFilter]
  );

  return (
    <AGPReportResponseDaysFilterContainer>
      <AgpReportResponseDaysFilterCheckboxes />
      <AGPReportResponseDaysFilterList>
        {filterOptions.map((filter, index) => (
          <AGPReportResponseDaysFilterListItem
            key={index}
            $active={daysFilter.some((item) => item.filterId === index)}
            onClick={() => handleFilters(filter, index)}
          >
            <p>{filter.label}</p>
            {switchAgp === "AGP" ? (
              <AGPReportResponseMiniAGPProvider filter={filter.value}>
                <AGPReportResponseMiniAGP
                  isDimmed={!daysFilter.some((item) => item.filterId === index)}
                />
              </AGPReportResponseMiniAGPProvider>
            ) : (
              <AgpReportResponseMiniOverlay filter={filter.value} />
            )}
          </AGPReportResponseDaysFilterListItem>
        ))}
      </AGPReportResponseDaysFilterList>
    </AGPReportResponseDaysFilterContainer>
  );
};

type FilterOption = { label: string; value: DaysFilterOptions };

function buildFilterOptions(
  countHowManyDaysWithinRange: (target: DaysFilterOptions) => number
): FilterOption[] {
  return [
    {
      label: `${countHowManyDaysWithinRange("Sundays")} Sun`,
      value: "Sundays",
    },
    {
      label: `${countHowManyDaysWithinRange("Mondays")} Mon`,
      value: "Mondays",
    },
    {
      label: `${countHowManyDaysWithinRange("Tuesdays")} Tues`,
      value: "Tuesdays",
    },
    {
      label: `${countHowManyDaysWithinRange("Wednesdays")} Wednes`,
      value: "Wednesdays",
    },
    {
      label: `${countHowManyDaysWithinRange("Thursdays")} Thurs`,
      value: "Thursdays",
    },
    {
      label: `${countHowManyDaysWithinRange("Fridays")} Fri`,
      value: "Fridays",
    },
    {
      label: `${countHowManyDaysWithinRange("Saturdays")} Sat`,
      value: "Saturdays",
    },
  ];
}
