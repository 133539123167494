import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterSpaceBetween,
  flexCol,
  mediumFont,
  mediumRegularFont,
  mediumSemiBoldFont,
  regularMediumFont,
  smallMediumFont,
  smallRegularFont,
  smallSemiBoldFont,
} from "./gloabl-classes";
import { Button } from "./reusable-classes";

export const TooltipWrapper = styled.div<{
  translateToolTip: string;
}>`
  transform: ${({ translateToolTip }) => translateToolTip};
`;

export const TooltipContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 0.5rem;
  width: 240px;
`;

export const TooltipHeader = styled.div`
  background: ${({ theme }) => theme.newColors.otidaO2};
  padding: 0.5rem;
  ${flexCenter}

  p {
    font-size: 1.1rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.titleColor};
  }

  strong {
    color: ${({ theme }) => theme.newColors.otida03};
    margin-left: auto;
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 16px;
    cursor: pointer;
    pointer-events: auto;
    margin-left: 8px;
  }
`;

export const ToolTipBody = styled.div`
  p {
    font-weight: 700;
    font-size: 1.2rem;
    text-align: center;

    span {
      font-size: 1rem;
      color: ${({ theme }) => theme.newColors.otida03};
    }
  }
`;

// Readings, In & Out of Range Tooltip Styles
export const ReadingTooltipBody = styled.div`
  ${flexCenterSpaceBetween}
  align-items: flex-start;
  padding: 16px 8px;
`;

export const ReadingTooltipReadingText = styled.p`
  font-size: 1.1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0px;
`;

export const ReadingTooltipTagText = styled.p`
  ${regularMediumFont}
  color: ${({ theme }) => theme.colors.textColor};
`;

export const ReadingTooltipInOrOutOfRangeTag = styled.div<{
  isInRange: boolean;
}>`
  color: ${({ theme }) => theme.colors.buttonColor};
  background: ${({ isInRange, theme }) =>
    isInRange ? theme.newColors.bgmChartGreen : theme.newColors.bgmChartRed};
  width: 100px;
  height: 25px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

// Patient Meal Tooltip
export const PatientMealSorurceTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.buttonColorAlt};
  margin: 8px;
`;

export const PatientMealBodySourcesContainer = styled.div`
  padding: 0px 8px 4px;
`;

export const PatientMealBodySourceContainer = styled.span`
  ${mediumFont}
  color: ${({ theme }) => theme.colors.buttonColorAlt};
`;

export const PatientMealTooltipContainer = styled.div`
  pointer-events: auto;
  background: ${({ theme }) => theme.colors.containerColor};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 0.5rem;
  width: 300px;
  display: flex;
  flex-direction: column;
`;

export const PatientMealTooltipHeader = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.newColors.otidaO2};
  padding: 0.5rem;
`;

export const PatientMealTooltipHeaderTop = styled.div`
  ${flexCenter}

  p {
    font-size: 1.1rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.titleColor};
    display: grid;

    span {
      font-size: 14px;
      margin-left: 0px;
      margin-top: 4px;
    }
  }

  div {
    ${flexCenter}
    margin-left: auto;

    strong {
      color: ${({ theme }) => theme.newColors.otida03};

      :nth-child(2) {
        margin-left: 8px;
      }
    }
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    cursor: pointer;
    margin-left: 8px;
    font-size: 16px;
  }
`;

export const PatientMealTooltipHeaderBottom = styled.div`
  ${flexCenterSpaceBetween}

  p {
    ${smallRegularFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;

export const PatientMealTooltipBody = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const PatientMealTooltipBodyVoiceNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0px;
`;

export const PatientMealTooltipBodyVoiceNoteContainer = styled.div`
  width: 95%;
  margin: 0px auto;

  audio {
    width: 100%;
    height: 40px;
  }
`;

export const PatientMealTooltipBodyImageContainer = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 12px 0px;
  cursor: pointer;

  img {
    width: 270px;
    border-radius: 8px;
    margin: 0px auto;
  }

  ${Button} {
    margin-left: auto;
    padding: 4px 8px;
  }
`;

export const PatientMealTooltipBodyNoteContainer = styled.div`
  padding: 4px 8px 0px;

  p {
    ${mediumRegularFont}
    color: ${({ theme }) => theme.newColors.textColorAlt};
    margin: 0px;
  }
`;

export const PatientMealBodyFoodGroupsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const PatientMealBodyFoodGroupContainer = styled.div`
  ${flexCenterSpaceBetween}
  margin: 8px;

  p {
    ${smallMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
  }

  span {
    ${mediumSemiBoldFont}
    color: ${({ theme }) => theme.newColors.textColorAlt};
  }
`;

// Meals Or Medications Tooltips
export const MealTooltipContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 0.5rem;
  width: 300px;
`;

export const MealtootipHeader = styled.div`
  ${flexCol};
  background: ${({ theme }) => theme.newColors.otidaO2};
  padding: ${({ theme }) => theme.padding.pad05All};

  p {
    font-size: 1.1rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.titleColor};
  }

  strong {
    color: ${({ theme }) => theme.newColors.otida03};
  }
`;

export const MealtootipHeaderTop = styled.div`
  ${flexCenter}

  strong {
    margin-left: auto;
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    cursor: pointer;
    pointer-events: auto;
    margin-left: 8px;
  }
`;

export const MealtootipHeaderBottom = styled.div`
  ${flexCenterSpaceBetween}

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;

export const MealTooltipBody = styled.div`
  padding: ${({ theme }) => theme.padding.px_05};
`;

export const MealTooltipBodyTitle = styled.p`
  ${mediumFont}
  color: ${({ theme }) => theme.colors.titleColor};
`;

export const MealTooltipBodyList = styled.ul`
  padding: 0;
  list-style: none;
`;

export const MealTooltipBodyListItem = styled.li`
  ${flexCenterSpaceBetween}
  padding: ${({ theme }) => theme.padding.py_05};

  span {
    ${smallRegularFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }

  strong {
    ${smallSemiBoldFont}
    color: ${({ theme }) => theme.colors.titleColor};
  }
`;

// Activities Tooltip
export const ActivitiesTooltipBody = styled.div`
  padding: 1rem 0.5rem 1.5rem;
`;

export const ActivitiesTooltipBodyItem = styled.div`
  ${flexCenterSpaceBetween}

  p {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.titleColor};
    margin: 0px;
  }

  span {
    ${regularMediumFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;
