import { DatesWithinRange } from "../../../../../../components/date-range/context/date-range-context";
import { PatientCGMEntryDTO } from "../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { DaysFilter } from "../../context/agp-days-filter-context";

export function filterPatientCgmEntriesByWeekday(
  patientCGMEntryDTOs: PatientCGMEntryDTO[],
  daysFilter: DaysFilter[],
  datesWithinRange: DatesWithinRange[]
) {
  if (daysFilter.length === 0) {
    return patientCGMEntryDTOs;
  } else {
    const dayFilter = daysFilter.map((item) =>
      item.filterName.slice(0, item.filterName.length - 1)
    );
    const datesToFilter = datesWithinRange
      .filter((date) => dayFilter.includes(date.weekDay))
      .map((item) => item.date);

    return patientCGMEntryDTOs.filter((entry) =>
      datesToFilter.includes(entry.date)
    );
  }
}
