import { PatientCGMEntryDTO } from "../../../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { calculateTimeDifferences } from "../../../../../../../../utils/glucose-point-utils/calculate-time-differences";
import { PatientCGMEntryDTOTimeDifference } from "../types/patient-cgm-entry-with-time-difference";

export function calculateTimeDifferencesTotal(
  patientCGMEntryDTOs: PatientCGMEntryDTO[]
): PatientCGMEntryDTOTimeDifference[] {
  return patientCGMEntryDTOs.map((patientCGMEntryDTO, index) => {
    return {
      ...patientCGMEntryDTO,
      timeDifference: calculateTimeDifferences(
        patientCGMEntryDTOs.map((reading) => reading.time.toString())
      )[index],
    };
  });
}
