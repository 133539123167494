import styled from "styled-components/macro";
import { lightShadowAlt } from "../../../../../../styles/global-style";
import {
  flexCenterCenter,
  flexCenterSpaceBetween,
  mediumSemiBoldFont,
} from "../../../../../../styles/classes/gloabl-classes";
import { EmptyStateBody } from "../../../../../../components/empty-state/styled-empty-state";

export const CompareTimeRangesContainer = styled.div`
  ${lightShadowAlt}
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const CompareTimeRangesHeader = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;

  p {
    ${mediumSemiBoldFont}
    margin: 0px;
  }
`;

export const CompareTimeRangesBody = styled.div`
  padding: 8px 16px;
  ${flexCenterSpaceBetween}
  height: calc(100% - 37px);
  min-height: 281px;

  ${EmptyStateBody} {
    margin: 0 auto;
    height: 100%;

    img {
      width: 100px;
      height: 100px;
    }
  }
`;

export const CompareTimeRangesBodyChartAndDetailsContainer = styled.div`
  ${flexCenterCenter}
  width: 100%;
  height: 265px;
  position: relative;

  .arrow {
    background: transparent;
    border: 2px solid #000;
    width: 35px;
    height: 18px;
    position: absolute;
  }

  .comapre-left-arrow {
    left: 30px;
  }
  .compare-right-arrow {
    left: 30px;
  }

  .arrow-top {
    top: 22px;
    border-bottom: 0px;
    border-right: 0px;
    border-radius: 8px 0px 0px 0px;
  }

  .arrow-bottom {
    bottom: 22px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px 0px 0px 8px;
  }
`;
