import styled, { css } from "styled-components/macro";
import { lightShadowAlt } from "../../../../../styles/global-style";
import {
  flexCenter,
  lgBoldFont,
  mediumSemiBoldFont,
  regularSemiBoldFont,
} from "../../../../../styles/classes/gloabl-classes";
import { EmptyStateBody } from "../../../../../components/empty-state/styled-empty-state";

export const CompareStatisticsContainer = styled.div`
  ${lightShadowAlt}
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const CompareStatisticsHeader = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;

  p {
    ${mediumSemiBoldFont}
    margin: 0px;
  }
`;

export const CompareStatisticsBody = styled.div`
  height: calc(100% - 37px);
  padding: 0px 16px;
  min-height: 164px;

  ${EmptyStateBody} {
    height: 100%;

    img {
      width: 100px;
      height: 100px;
    }
  }
`;

const TopListItemCSS = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 16px;
  height: 110px;

  strong {
    font-size: 14px;
  }
`;

export const CompareStatisticsBodyItem = styled.div<{
  $topListItem?: boolean;
}>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  ${({ $topListItem }) => ($topListItem ? TopListItemCSS : ``)};

  span {
    ${lgBoldFont}

    span {
      ${regularSemiBoldFont}
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;

export const CompareStatisticsBodyItemSubContainer = styled.div`
  width: 50%;
`;

export const CompareStatisticsBodyItemTitle = styled.p`
  ${regularSemiBoldFont}

  span {
    ${mediumSemiBoldFont}
    display: block;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const CompareStatisticsBodyItemGMITitle = styled.div`
  ${flexCenter}

  p {
    ${regularSemiBoldFont}
  }
`;

export const CompareGMITitleInfo = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 4px 8px;
  border-radius: 4px;
  width: 400px;
  position: absolute;
  right: 0px;
  ${lightShadowAlt}
  display: none;

  p {
    ${regularSemiBoldFont}
  }

  span {
    ${mediumSemiBoldFont}
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const CompareGMITitleIcon = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.textColor};
  cursor: pointer;
  font-size: 16px !important;
  margin-left: 12px;
`;

export const CompareGMITitleInfoContainer = styled.div`
  position: relative;

  ${CompareGMITitleIcon}:hover + ${CompareGMITitleInfo} {
      display: block;
  }
`;
