import { AgpPercentile } from "../../../../utils/agp-calculate-percentile";
import { groupPercentiles } from "../group-percentiles";

type AgpSeries95th5th = {
  x: Date;
  high95th: number;
  low5th: number;
};

export function buildAgpSeries95th5th(
  percentile5: AgpPercentile[],
  percentile95: AgpPercentile[]
): AgpSeries95th5th[] {
  const agpSeries95th5th: AgpSeries95th5th[] = [];

  const percentiles5thAnd95th = groupPercentiles([percentile5, percentile95]);

  for (const percentile5thAnd95th of percentiles5thAnd95th) {
    const x = new Date(`2024-01-01T${percentile5thAnd95th.hour}:00:00`);

    const high95th = percentile5thAnd95th.cgmEntries.find(
      (i) => i.keyPercentile === "95%"
    );
    const low5th = percentile5thAnd95th.cgmEntries.find(
      (i) => i.keyPercentile === "5%"
    );

    if (high95th && low5th) {
      agpSeries95th5th.push({
        x,
        high95th: high95th.glucoseMGPerDL,
        low5th: low5th.glucoseMGPerDL,
      });
    }
  }

  if (agpSeries95th5th.length !== 0) {
    agpSeries95th5th.push({
      x: new Date(`2024-01-02T00:00:00`),
      high95th: agpSeries95th5th[0].high95th,
      low5th: agpSeries95th5th[0].low5th,
    });
  }

  return agpSeries95th5th;
}
