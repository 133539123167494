import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCol,
  mediumFont,
} from "../../../styles/classes/gloabl-classes";
import { transition } from "../../../styles/global-style";

export const DateRangeSelectedDatesContainer = styled.div`
  ${flexCenter}
  align-items: flex-start;
  gap: 12px;
  margin-top: 8px;
`;

export const DateRangeSelectedDatesText = styled.p`
  ${mediumFont}
  ${flexCol}
  margin: 0px 0px 8px;

  span {
    color: ${({ theme }) => theme.colors.textColor};
    ${mediumFont}
  }
`;

export const DateRangeButtonsContainer = styled.div`
  ${flexCenter}
  gap: 8px;
`;

export const DateRangeButton = styled.button`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenterCenter}
  width: 25px;
  height: 25px;
  border-radius: 4px;
  transition: ${transition};

  span {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 12px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    transition: ${transition};
  }

  :hover {
    background: ${({ theme }) => theme.colors.primaryColorAlt};

    span {
      color: ${({ theme }) => theme.colors.buttonColor};
    }
  }
`;
