import { useParams } from "react-router-dom";
import { Loading } from "../../../../../components/loading";
import { AGPReportResponseCards } from "./agp-report-response-cards/agp-report-response-cards";
import { useGetPatient } from "../../../../../hooks/patient-hooks/use-get-patient";
import { useGetPatientCGMEntries } from "../../../../../hooks/use-get-patient-cgm-entries";
import { useCallback } from "react";
import { AGPReportResponceCharts } from "./agp-report-response-charts/agp-report-response-charts";
import { AGPReportResponseAGP } from "./agp-report-response-agp/agp-report-response-agp";
import { useDateRange } from "../../../../../components/date-range/context/date-range-context";
import { AGPReportResponseHeader } from "./agp-report-response-header/agp-report-response-header";
import { AGPReportLoadedResponse } from "../context/agp-report-loaded-response-context";
import { AGPReportProviders } from "../context/agp-report-providers";
import { useGetPatientMeals } from "../../../../../hooks/patient-meal-hooks/use-get-patient-meals";
import { useGetPatientInsulinLogs } from "../../../../../hooks/patient-insulin-logs-hooks/use-get-patient-insulin-logs";
import { useGetPatientActivityLogStats } from "../../../../../hooks/patient-activity-logs-hooks/use-get-patient-activity-log-stats";

export const AGPReportResponse = () => {
  const { id } = useParams();
  const patientID = parseInt(id!);

  const getPatient = useGetPatient();
  const getPatientCGMEntries = useGetPatientCGMEntries();
  const getPatientMeals = useGetPatientMeals();
  const getPatientInsulinLogDTOs = useGetPatientInsulinLogs();
  const getPatientActivityLogDTO = useGetPatientActivityLogStats();

  const { temporalStartDate, temporalEndDate } = useDateRange();

  const cgmReportResponseLoadCallback = useCallback(async (): Promise<
    AGPReportLoadedResponse
  > => {
    const patientDTO = await getPatient(patientID);

    const [
      patientCGMEntryDTOs,
      patientMealsDTOs,
      patientInsulinLogDTOs,
      patientActivityLogStats,
    ] = await Promise.all([
      getPatientCGMEntries(
        patientID,
        undefined,
        temporalStartDate,
        temporalEndDate
      ),
      getPatientMeals(patientID, undefined, temporalStartDate, temporalEndDate),
      getPatientInsulinLogDTOs(patientID, temporalStartDate, temporalEndDate),
      getPatientActivityLogDTO(patientID, temporalStartDate, temporalEndDate),
    ]);

    return {
      patientDTO,
      patientCGMEntryDTOs,
      patientMealsDTOs,
      patientInsulinLogDTOs,
      patientActivityLogStats,
    };
  }, [
    patientID,
    getPatient,
    temporalStartDate,
    temporalEndDate,
    getPatientCGMEntries,
    getPatientMeals,
    getPatientInsulinLogDTOs,
    getPatientActivityLogDTO,
  ]);

  return (
    <>
      <AGPReportResponseHeader />
      <Loading
        load={cgmReportResponseLoadCallback}
        successComponent={(cgmReportResponse) => (
          <AGPReportProviders cgmReportResponse={cgmReportResponse}>
            <AGPReportResponseCards />
            <AGPReportResponseAGP />
            <AGPReportResponceCharts />
          </AGPReportProviders>
        )}
      />
    </>
  );
};
