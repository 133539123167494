import { PatientType } from "../../../../../../../../../models/patient-dtos/patient-type";

export function isGlucosePointInRange(
  type: PatientType,
  glucoseMGPerDL: number,
  tag?: string
) {
  if (tag === "fasting") {
    if (type === "NormalOrHealthy" || type === "Prediabetic") {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 100;
    } else if (type === "Type1Pediatric") {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 144;
    } else {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 130;
    }
  } else {
    if (type === "NormalOrHealthy" || type === "Prediabetic") {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 140;
    } else {
      return glucoseMGPerDL >= 70 && glucoseMGPerDL <= 180;
    }
  }
}
