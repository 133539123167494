import { PatientCGMEntryDTO } from "../../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { GroupCGMEntriesByTimeInterval } from "../../context/agp-loaded-response-context";

export type AgpPercentile = {
  time: string;
  hour: string;
  keyPercentile: string;
  cgmEntry: PatientCGMEntryDTO;
};

export function agpCalculatePercentile(
  percentile: number,
  sortedGroupCGMEntriesByGlucose: GroupCGMEntriesByTimeInterval[]
) {
  const agpPercentiles: AgpPercentile[] = [];

  let index = 0;
  for (const groupedItem of sortedGroupCGMEntriesByGlucose) {
    const hour = index >= 0 && index <= 9 ? `0${index}` : index.toString();
    const cgmEntries = groupedItem.cgmEntries;

    const indexOfPercentile = (percentile / 100) * (cgmEntries.length - 1) + 1;

    if (indexOfPercentile % 1 === 0) {
      const percentileEntry = cgmEntries[indexOfPercentile - 1];

      agpPercentiles.push({
        time: groupedItem.time,
        hour: hour,
        keyPercentile: `${percentile}%`,
        cgmEntry: percentileEntry,
      });
    } else {
      const k = Math.floor(indexOfPercentile);
      const f = indexOfPercentile - k;
      const percentileEntry = cgmEntries[k];

      agpPercentiles.push({
        time: groupedItem.time,
        hour: hour,
        keyPercentile: `${percentile}%`,
        cgmEntry: {
          ...percentileEntry,
          time: groupedItem.time,
          glucoseMGPerDL: Math.floor(
            cgmEntries[k - 1].glucoseMGPerDL +
              (cgmEntries[k].glucoseMGPerDL -
                cgmEntries[k - 1].glucoseMGPerDL) *
                f
          ),
        },
      });
    }

    index += 1;
  }

  return agpPercentiles;
}
