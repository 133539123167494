import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { PatientCGMEntryDTO } from "../../../../../models/patient-cgm-entry-dtos/patient-cgm-entry-dto";
import { PatientDTO } from "../../../../../models/patient-dtos/patient-dto";
import { PatientMealDTO } from "../../../../../models/patient-meal-dtos/patient-meal-dto";
import { PatientInsulinLogDTO } from "../../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import { PatientActivityLogStatsDTO } from "../../../../../models/patient-activity-log-dtos/patient-activity-log-stats-dto";
import { PatientActivityLogDTO } from "../../../../../models/patient-activity-log-dtos/patient-activity-log-dto";

export type GroupCgmEntriesByDate = {
  date: string;
  day: string;
  readings: PatientCGMEntryDTO[];
};

export type AGPReportLoadedResponse = {
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
  patientMealsDTOs: PatientMealDTO[];
  patientInsulinLogDTOs: PatientInsulinLogDTO[];
  patientActivityLogStats: PatientActivityLogStatsDTO;
};

type TAGPReportLoadedResponse = {
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
  groupCgmEntriesByDate: GroupCgmEntriesByDate[];
  patientMealsDTOs: PatientMealDTO[];
  patientInsulinLogDTOs: PatientInsulinLogDTO[];
  patientActivityLogs: PatientActivityLogDTO[];
};

const AGPReportLoadedResponseContext = createContext<
  TAGPReportLoadedResponse | undefined
>(undefined);

type AGPReportLoadedResponseProviderProps = PropsWithChildren<{
  patientDTO: PatientDTO;
  patientCGMEntryDTOs: PatientCGMEntryDTO[];
  patientMealsDTOs: PatientMealDTO[];
  patientInsulinLogDTOs: PatientInsulinLogDTO[];
  patientActivityLogStats: PatientActivityLogStatsDTO;
}>;

export function AGPReportLoadedResponseProvider(
  props: AGPReportLoadedResponseProviderProps
) {
  const {
    children,
    patientDTO,
    patientCGMEntryDTOs,
    patientMealsDTOs,
    patientInsulinLogDTOs,
    patientActivityLogStats,
  } = props;

  const { groupCgmEntriesByDate } = useMemo(() => {
    const groupCgmEntriesByDate: GroupCgmEntriesByDate[] = [];

    for (const entry of patientCGMEntryDTOs.filter(
      (item) => item.source === "Libreview"
    )) {
      const existingEntry = groupCgmEntriesByDate.find(
        (e) => e.date === entry.date
      );

      if (existingEntry) {
        existingEntry.readings.push(entry);
      } else {
        groupCgmEntriesByDate.push({
          date: entry.date,
          readings: [entry],
          day: new Date(entry.date).toLocaleDateString("en-US", {
            weekday: "long",
          }),
        });
      }
    }

    return { groupCgmEntriesByDate };
  }, [patientCGMEntryDTOs]);

  const patientActivityLogs = useMemo((): PatientActivityLogDTO[] => {
    const patientActivityLogDTOs: PatientActivityLogDTO[] = [];

    if (
      patientActivityLogStats.activityModes !== undefined &&
      patientActivityLogStats.activityModes.length > 0
    ) {
      for (const PatientActivityLogStatsActivityModeDTO of patientActivityLogStats.activityModes) {
        for (const patientActivityLogStatsActivityModeDayDTO of PatientActivityLogStatsActivityModeDTO.days) {
          for (const PatientActivityLogDTO of patientActivityLogStatsActivityModeDayDTO.patientActivityLogs) {
            patientActivityLogDTOs.push(PatientActivityLogDTO);
          }
        }
      }
    }

    return patientActivityLogDTOs;
  }, [patientActivityLogStats]);

  const value = useMemo(
    () => ({
      patientDTO,
      patientCGMEntryDTOs,
      groupCgmEntriesByDate,
      patientMealsDTOs,
      patientInsulinLogDTOs,
      patientActivityLogs,
    }),
    [
      patientDTO,
      patientCGMEntryDTOs,
      groupCgmEntriesByDate,
      patientMealsDTOs,
      patientInsulinLogDTOs,
      patientActivityLogs,
    ]
  );

  return (
    <AGPReportLoadedResponseContext.Provider value={value}>
      {children}
    </AGPReportLoadedResponseContext.Provider>
  );
}

export function useAGPReportLoadedResponse(): TAGPReportLoadedResponse {
  const AGPReportLoadedResponse = useContext(AGPReportLoadedResponseContext);

  if (AGPReportLoadedResponse === undefined) {
    throw new Error(
      "useAGPReportLoadedResponse must be used within a AGPReportLoadedResponseProvider"
    );
  }

  return AGPReportLoadedResponse;
}
