import { useDateRange } from "./context/date-range-context";
import { useCallback, useState } from "react";
import { DateRangeContainer } from "./styled-date-range";
import { Dropdown } from "../dropdown/dropdown";
import { DropdownListItem } from "../dropdown/styled-dropdown";
import { Button } from "../../styles/classes/reusable-classes";
import { DateRangeInputPicker } from "./date-range-input-picker/date-range-input-picker";
import { DateRangeSelectedDates } from "./date-range-selected-dates/date-range-selected-dates";

export type DateRangeDropdownState =
  | "1 Week"
  | "2 Weeks"
  | "30 Days"
  | "90 Days"
  | "Date Range";

export type DateRangeDropdownOptions = {
  optionId: number;
  value: DateRangeDropdownState;
  handlerValue?: 15 | 31 | 91;
};

const dropdownOptions: DateRangeDropdownOptions[] = [
  {
    optionId: 1,
    value: "2 Weeks",
    handlerValue: 15,
  },
  {
    optionId: 2,
    value: "30 Days",
    handlerValue: 31,
  },
  {
    optionId: 3,
    value: "90 Days",
    handlerValue: 91,
  },
  {
    optionId: 4,
    value: "Date Range",
  },
];

type DateRangeProps = {
  showResetDatesButton?: boolean;
  initialState?: DateRangeDropdownState;
  customDropdownOptions?: DateRangeDropdownOptions[];
  width?: number;
};

export const DateRange = (props: DateRangeProps) => {
  const {
    showResetDatesButton,
    initialState,
    customDropdownOptions,
    width,
  } = props;

  const {
    dateRangeDropdownHandler,
    dateRangePickerRef,
    temporalStartDate,
    temporalEndDate,
    resetDates,
  } = useDateRange();

  const [dateRangeDropdownState, setDateRangeDropdownState] = useState<
    DateRangeDropdownState | undefined
  >(
    initialState
      ? initialState
      : !temporalStartDate && !temporalEndDate
      ? undefined
      : "2 Weeks"
  );

  const handleResetDates = useCallback(() => {
    resetDates();
    setDateRangeDropdownState(undefined);
  }, [resetDates]);

  const renderDropdownOptions = customDropdownOptions
    ? customDropdownOptions
    : dropdownOptions;

  if (renderDropdownOptions.length === 1) {
    if (renderDropdownOptions.some((item) => item.value === "Date Range")) {
      return (
        <DateRangeContainer>
          <Button
            onClick={() => {
              dateRangePickerRef.current?.show();
              setDateRangeDropdownState("Date Range");
            }}
          >
            Date Range
            <DateRangeInputPicker />
          </Button>
          <DateRangeSelectedDates
            handleResetDates={handleResetDates}
            showResetDatesButton={showResetDatesButton}
          />
        </DateRangeContainer>
      );
    }
  }

  return (
    <DateRangeContainer>
      <Dropdown
        text={
          dateRangeDropdownState ? dateRangeDropdownState : "Select Date Range"
        }
        variant="designed-ux"
        width={width ? width : 300}
      >
        {renderDropdownOptions.map((option) => {
          if (option.value === "Date Range") {
            return (
              <DropdownListItem
                key={option.optionId}
                variant="designed-ux"
                onClick={() => {
                  dateRangePickerRef.current?.show();
                  setDateRangeDropdownState("Date Range");
                }}
                active={dateRangeDropdownState === "Date Range"}
              >
                {option.value}
                <DateRangeInputPicker />
              </DropdownListItem>
            );
          } else {
            return (
              <DropdownListItem
                key={option.optionId}
                variant="designed-ux"
                onClick={() => {
                  /*
                   * If the option value !== "Custom Range",
                   * Then the handler value will always be defined
                   */
                  dateRangeDropdownHandler(option.handlerValue!);
                  setDateRangeDropdownState(option.value);
                }}
                active={dateRangeDropdownState === option.value}
              >
                {option.value}
              </DropdownListItem>
            );
          }
        })}
      </Dropdown>
      <DateRangeSelectedDates
        handleResetDates={handleResetDates}
        showResetDatesButton={showResetDatesButton}
      />
    </DateRangeContainer>
  );
};
