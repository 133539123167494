import { AxisModel } from "@syncfusion/ej2-react-charts";

export function buildPrimaryYAxis(
  maxY: number,
  inRangeNumbers: number[]
): AxisModel {
  return {
    visible: false,
    minimum: 0,
    maximum: maxY,
    interval: 10,
    majorGridLines: {
      color: "transparent",
      width: 0,
    },
    majorTickLines: {
      width: 0,
    },
    stripLines: [
      {
        start: 0,
        end: inRangeNumbers[0],
        color: "#fff",
        opacity: 0.1,
        visible: true,
      },
      {
        start: inRangeNumbers[0],
        end: inRangeNumbers[0] + 1,
        color: "#219653",
        opacity: 1,
        size: 2,
        sizeType: "Pixel",
        zIndex: "Over",
      },
      {
        start: inRangeNumbers[0],
        end: inRangeNumbers[1],
        color: "#fff",
        opacity: 0.1,
        visible: true,
      },
      {
        start: inRangeNumbers[1],
        end: inRangeNumbers[1] + 1,
        color: "#219653",
        opacity: 1,
        size: 2,
        sizeType: "Pixel",
        zIndex: "Over",
      },
      {
        start: inRangeNumbers[1],
        end: maxY,
        color: "#fff",
        opacity: 0.1,
        visible: true,
      },
    ],
  };
}
