import styled from "styled-components/macro";
import { lightShadowAlt } from "../../../../../../styles/global-style";
import { mediumSemiBoldFont } from "../../../../../../styles/classes/gloabl-classes";
import { EmptyStateBody } from "../../../../../../components/empty-state/styled-empty-state";

export const CompareAGPContainer = styled.div`
  margin-bottom: 24px;
`;

export const CompareAGPCard = styled.div`
  ${lightShadowAlt}
  border-radius: 8px;
  margin-top: 32px;
`;

export const CompareAGPHeader = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;

  p {
    ${mediumSemiBoldFont}
    margin: 0px;
  }
`;

export const CompareAGPBody = styled.div<{ $isEmpty: boolean }>`
  padding: 8px 16px;
  height: calc(100% - 37px);
  overflow-x: hidden;
  display: ${({ $isEmpty }) => ($isEmpty ? "block" : "grid")};
  min-height: 351px;

  ${EmptyStateBody} {
    margin: 0 auto;
    height: 300px;

    img {
      width: 100px;
      height: 100px;
    }
  }
`;

export const CompareAGPGraphContainer = styled.div`
  margin: 0 auto;
  max-width: 929px;
  width: 100%;
`;
