import { AxisModel } from "@syncfusion/ej2-react-charts";

export function buildPrimaryXAxis(startDate: Date, endDate: Date): AxisModel {
  return {
    valueType: "DateTime",
    visible: false,
    edgeLabelPlacement: "Shift",
    labelFormat: "hh:mm",
    majorGridLines: {
      color: "#0000001f",
      width: 2,
    },
    interval: 3,
    minimum: startDate,
    maximum: endDate,
  };
}
