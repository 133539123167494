import { TooltipSettingsModel } from "@syncfusion/ej2-react-charts";
import { AgpTooltip } from "./agp-tooltip";

export function buildAgpTooltip(): TooltipSettingsModel {
  const template: any = chartTemplate;

  return {
    enable: true,
    template,
    shared: true,
    enableMarker: true,
  };
}

function chartTemplate(args: any) {
  return <AgpTooltip {...args} />;
}
