import styled from "styled-components/macro";
import {
  flexCenterSpaceBetween,
  mediumFont,
  mediumSemiBoldFont,
} from "../../../../../../../../styles/classes/gloabl-classes";

export const AGPReportResponseTimeRangesDetailsContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
`;

export const AGPReportResponseTimeRangesDetailsItem = styled.div<{
  $height: string;
}>`
  border-bottom: 2px dashed ${({ theme }) => theme.colors.borderColor};
  ${flexCenterSpaceBetween}
  padding: 8px 0px 4px;
  height: ${({ $height }) => `${$height}`};
  max-height: 25%;
  min-height: 18px;

  p {
    margin: 0px;
    ${mediumSemiBoldFont}
    position: relative;
    display: flex;
    align-items: center;

    span {
      ${mediumFont}
      color: ${({ theme }) => theme.colors.textColor};
      margin-left: 8px;
    }

    ::before {
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 100%;
      position: absolute;
      top: 35%;
      left: -10px;
    }
  }

  :nth-child(1) {
    margin-bottom: auto;
    height: 18%;
  }

  :nth-child(5) {
    margin-top: auto;
    height: 18%;
  }

  :nth-child(1) > p {
    ::before {
      background: ${({ theme }) => theme.colors.warning};
    }
  }

  :nth-child(2) > p {
    ::before {
      background: #f1d98b;
    }
  }

  :nth-child(3) > p {
    ::before {
      background: ${({ theme }) => theme.colors.success};
    }
  }

  :nth-child(4) > p {
    ::before {
      background: ${({ theme }) => theme.colors.danger};
    }
  }

  :nth-child(5) > p {
    ::before {
      background: #6d2f33;
    }
  }

  strong {
    margin-left: 24px;
  }
`;
