import { PatientInsulinLogDTO } from "../../../../models/patient-insulin-log-dtos/patient-insulin-log-dto";
import { ToolTipBody } from "../../../../styles/classes/tooltips";
import { CgmTooltipContainer, CgmTooltipHeader } from "./styled-cgm-tooltip";

type InsulinTooltipProps = {
  x: Date;
  y: number;
  data: PatientInsulinLogDTO;
};

export const InsulinTooltip = (props: InsulinTooltipProps) => {
  const { x, y, data } = props;

  return (
    <CgmTooltipContainer style={{ width: "250px" }}>
      <CgmTooltipHeader>
        <p>Insulin</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            justifyContent: "space-between",
          }}
        >
          <span>
            {x.toLocaleString("en-UK", {
              year: undefined,
              month: undefined,
              day: undefined,
              hour12: true,
              hour: "2-digit",
              minute: "2-digit",
              second: undefined,
            })}
          </span>
          <span className="material-symbols-outlined">close</span>
        </div>
      </CgmTooltipHeader>
      <ToolTipBody>
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            justifyContent: "center",
          }}
        >
          <p>
            {y} <span>Units</span>
          </p>
          <p>
            <span>{data.insulinMedication.name}</span>
          </p>
        </div>
      </ToolTipBody>
    </CgmTooltipContainer>
  );
};
