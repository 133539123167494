import styled from "styled-components/macro";
import { lightShadowAlt, transition } from "../../../../styles/global-style";
import { flexCenter } from "../../../../styles/classes/gloabl-classes";

export const SidebarUserInfoUserContainer = styled.div`
  position: relative;
`;

export const SidebarUserInfoUserImageContainer = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const SidebarUserInfoUserInfoWrapper = styled.div<{ $show: boolean }>`
  background: ${({ theme }) => theme.colors.containerColor};
  color: ${({ theme }) => theme.colors.titleColor};
  position: absolute;
  top: -50px;
  left: 50px;
  width: 200px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${lightShadowAlt}
  border-radius: 8px;
  display: ${({ $show }) => ($show ? "block" : "none")};

  h4 {
    margin: 0px;
    padding: 8px;
  }
`;

export const SidebarUserInfoLogoutIcon = styled.div`
  cursor: pointer;
  ${flexCenter}
  transition: ${transition};
  padding: 8px;

  p {
    margin: 0px;
  }

  :hover {
    background: ${({ theme }) => theme.colors.primaryColor};
    color: ${({ theme }) => theme.colors.buttonColor};
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
`;
