import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
} from "../../../../../../../../styles/classes/gloabl-classes";

export const BGMTrendsByMealChartContainer = styled.div`
  height: 370px;
  padding: 8px 8px 16px;
`;

export const BGMTrendsByMealChartLegend = styled.div`
  ${flexCenterCenter}
  margin: 16px 0px;
`;

export const BGMTrendsByMealChartPoints = styled.ul`
  ${flexCenter}
  gap: 0px 21px;
  margin: 0px;
`;

export const BGMTrendsByMealChartPoint = styled.li`
  ${flexCenter}

  div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  span {
    font-size: 13px;
    color: #000;
    margin-left: 8px;
  }
`;
