import styled from "styled-components/macro";
import {
  regularRegularFont,
  smallSemiBoldFont,
} from "../../../../../../../styles/classes/gloabl-classes";

export const AGPReportResponseChartContainer = styled.div<{
  $showYAxisValues: boolean;
}>`
  width: 100%;
  position: relative;

  ${({ $showYAxisValues }) =>
    !$showYAxisValues
      ? `svg > g > g[id*="AxisGroup1Inside"] {
          display: none;
        }`
      : ``};

  [id*="AxisLabels0"] {
    display: none;
  }
`;

export const AGPReportResponseChartWeekDay = styled.p`
  ${regularRegularFont}
  text-align: center;
  margin: 0px 0px 0px 60px;
`;

export const AGPReportResponseChartWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const AGPReportResponseChartDayDate = styled.p`
  margin: 0px;
  position: absolute;
  ${smallSemiBoldFont};
  top: 15px;
  left: 45px;
  z-index: 100;
`;

export const AGPReportResponseChartDayXAxisLabelsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -26px;
  margin-left: 25px;
  width: calc(100%);

  span {
    font-size: 12px;

    :nth-child(2) {
      margin: 0 auto;
      color: ${({ theme }) => theme.colors.textColor};
    }
  }

  @media screen and (max-width: 1441px) {
    width: 100%;
  }
`;
