import { useState } from "react";
import styled, { CSSProperties } from "styled-components/macro";
import { NavbarHeight, SidebarWidth } from "../../styles/global-style";
import { Sidebar } from "./sidebar/sidebar";
import { Outlet, useLocation, useParams } from "react-router-dom";

export const Layout = () => {
  const [showResponsiveSideber, setShowResponsiveSidebar] = useState(false);
  const handleHideResponsiveSideber = () => setShowResponsiveSidebar(false);

  const location = useLocation();
  const { id } = useParams();

  const isSinglePatientLocation =
    location.pathname.includes("patients") &&
    id !== undefined &&
    !location.pathname.includes("patient-profile");

  const style: CSSProperties = isSinglePatientLocation
    ? { overflow: "hidden", height: "100vh" }
    : {};

  return (
    <div style={style}>
      <Sidebar
        showResponsiveSideber={showResponsiveSideber}
        handleHideResponsiveSideber={handleHideResponsiveSideber}
      />
      <ChildrenContainer>
        <Outlet />
      </ChildrenContainer>
    </div>
  );
};

const ChildrenContainer = styled.div`
  margin: ${NavbarHeight} 0 0 ${SidebarWidth};

  @media screen and (max-width: 500px) {
    margin: ${NavbarHeight} 0 0 0;
  }
`;
