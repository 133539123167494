import { useMemo } from "react";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import {
  AGPReportResponseChartsBody,
  AGPReportResponseChartsCard,
  AGPReportResponseChartsContainer,
  AGPReportResponseChartsHeader,
} from "./styled-agp-report-response-charts";
import { AGPReportResponseChart } from "./agp-report-response-chart/agp-report-response-chart";
import {
  AGPReportResponseChartContainer,
  AGPReportResponseChartWeekDay,
} from "./agp-report-response-chart/styled-agp-report-response-chart";
import { useAGPReportLoadedResponse } from "../../context/agp-report-loaded-response-context";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const AGPReportResponceCharts = () => {
  const { groupCgmEntriesByDate } = useAGPReportLoadedResponse();

  const maxYAxisValue = useMemo(() => {
    return Math.max(
      ...groupCgmEntriesByDate
        .map((entry) => entry.readings)
        .flat()
        .map((glucoseValue) => glucoseValue.glucoseMGPerDL)
    );
  }, [groupCgmEntriesByDate]);

  if (groupCgmEntriesByDate.length === 0) {
    return (
      <AGPReportResponseChartsContainer>
        <AGPReportResponseChartsCard>
          <AGPReportResponseChartsHeader>
            <p>Daily Glucose Profiles</p>
          </AGPReportResponseChartsHeader>
          <AGPReportResponseChartsBody $isEmpty>
            <EmptyState
              button={<></>}
              hasHeader={false}
              hasSearch={false}
              text="No Data Within the selected dates"
              title=""
            />
          </AGPReportResponseChartsBody>
        </AGPReportResponseChartsCard>
      </AGPReportResponseChartsContainer>
    );
  }

  const firstDay = groupCgmEntriesByDate[0].day;
  const startIndex = days.indexOf(firstDay); // Index of the first day (e.g., Friday -> 5)

  // Add placeholders for days before the first day
  const placeholders = Array(startIndex).fill(null);

  return (
    <AGPReportResponseChartsContainer>
      <AGPReportResponseChartsCard>
        <AGPReportResponseChartsHeader>
          <p>Daily Glucose Profiles</p>
        </AGPReportResponseChartsHeader>
        <AGPReportResponseChartsBody $isEmpty={false}>
          {days.map((day, index) => (
            <AGPReportResponseChartWeekDay key={index}>
              {day}
            </AGPReportResponseChartWeekDay>
          ))}
          {placeholders.map((_, index) => (
            <AGPReportResponseChartContainer
              key={`placeholder-${index}`}
              $showYAxisValues={false}
            >
              {/* Render an empty div or placeholder for the missing days */}
              <div className="empty-day-placeholder"></div>
            </AGPReportResponseChartContainer>
          ))}
          {groupCgmEntriesByDate.map((entry, index) => {
            const dayIndex = startIndex + index;
            return (
              <AGPReportResponseChartContainer
                key={entry.date} // unique key based on date
                $showYAxisValues={dayIndex % 7 === 0 || index === 0}
              >
                <AGPReportResponseChart
                  date={entry.date}
                  patientCGMEntryDTOs={entry.readings}
                  showYAxisValues={dayIndex % 7 === 0 || index === 0}
                  maxYAxisValue={maxYAxisValue > 200 ? maxYAxisValue + 50 : 250}
                />
              </AGPReportResponseChartContainer>
            );
          })}
        </AGPReportResponseChartsBody>
      </AGPReportResponseChartsCard>
    </AGPReportResponseChartsContainer>
  );
};
