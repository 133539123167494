import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { useDateRange } from "../../../../../components/date-range/context/date-range-context";

export type DaysFilterOptions =
  | "Sundays"
  | "Mondays"
  | "Tuesdays"
  | "Wednesdays"
  | "Thursdays"
  | "Fridays"
  | "Saturdays";

export type DaysFilter = {
  filterId: number;
  filterName: DaysFilterOptions;
};

type AGPDaysFilter = {
  daysFilter: DaysFilter[];
  setDaysFilter: (daysFilter2: DaysFilter[]) => void;
  countHowManyDaysWithinRange: (target: DaysFilterOptions) => number;
};

export const AGPDaysFilterContext = createContext<AGPDaysFilter | undefined>(
  undefined
);

type AGPDaysFilterProviderProps = PropsWithChildren<{}>;

export function AGPDaysFilterProvider(props: AGPDaysFilterProviderProps) {
  const { children } = props;

  const [daysFilter, setDaysFilter] = useState<DaysFilter[]>([]);

  const { selectedDates } = useDateRange();

  const countHowManyDaysWithinRange = useCallback(
    (target: DaysFilterOptions) => {
      return selectedDates.datesWithinRange.filter(
        (date) => date.weekDay === target.slice(0, target.length - 1)
      ).length;
    },
    [selectedDates.datesWithinRange]
  );

  return (
    <AGPDaysFilterContext.Provider
      value={{
        daysFilter,
        setDaysFilter,
        countHowManyDaysWithinRange,
      }}
    >
      {children}
    </AGPDaysFilterContext.Provider>
  );
}

export function useAGPDaysFilter(): AGPDaysFilter {
  const AGPDaysFilter = useContext(AGPDaysFilterContext);

  if (AGPDaysFilter === undefined) {
    throw new Error(
      "useAGPDaysFilter must be used within a AGPDaysFilterProvider"
    );
  }
  return AGPDaysFilter;
}
