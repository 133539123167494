import { Temporal } from "temporal-polyfill";
import { GroupCgmEntriesByDate } from "../../../../../context/agp-report-loaded-response-context";
import { GAP_THRESHOLD_MINUTES } from "../../../../../../../../../constants/config";

type OverlaySeriesItem = {
  x: Date;
  y?: number;
};

type OverlaySeries = {
  day: string;
  date: string;
  series: OverlaySeriesItem[];
  seriesColor: string;
};

export function buildOverlaySeries(
  groupCgmEntriesByDate: GroupCgmEntriesByDate[]
): OverlaySeries[] {
  const overlaySeries: OverlaySeries[] = [];

  let lastPlainDateTime: Temporal.PlainDateTime | undefined = undefined;
  for (const entry of groupCgmEntriesByDate) {
    const series: OverlaySeriesItem[] = [];

    for (const reading of entry.readings) {
      const plainDateTime = Temporal.PlainDate.from(
        `2024-01-01`
      ).toPlainDateTime(Temporal.PlainTime.from(reading.time));

      if (
        lastPlainDateTime !== undefined &&
        Temporal.PlainDateTime.compare(
          lastPlainDateTime.add({ minutes: GAP_THRESHOLD_MINUTES }),
          plainDateTime
        ) === -1
      ) {
        series.push({
          x: new Date(`2024-01-01T${reading.time}`),
        });
      }

      series.push({
        x: new Date(`2024-01-01T${reading.time}`),
        y: reading.glucoseMGPerDL,
      });

      lastPlainDateTime = plainDateTime;
    }

    overlaySeries.push({
      day: entry.day,
      date: entry.date,
      series,
      seriesColor:
        entry.day === "Sunday"
          ? "#d4a68a"
          : entry.day === "Monday"
          ? "#def094"
          : entry.day === "Tuesday"
          ? "#7be470"
          : entry.day === "Wednesday"
          ? "#abe3d1"
          : entry.day === "Thursday"
          ? "#adb8e3"
          : entry.day === "Friday"
          ? "#e39ff0"
          : entry.day === "Saturday"
          ? "#fcaf6e"
          : "transparent",
    });
  }

  return overlaySeries;
}
