import { useEffect, useRef, useState } from "react";
import {
  SidebarUserInfoLogoutIcon,
  SidebarUserInfoUserContainer,
  SidebarUserInfoUserImageContainer,
  SidebarUserInfoUserInfoWrapper,
} from "./styled-sidebar-user-info";
import {
  useAuth,
  useUser,
} from "../../../../context/auth-context/auth-context";

export const SidebarUserInfo = () => {
  const [showUserInfo, setShowUserInfo] = useState(false);
  const { clearAndUnpersistUser } = useAuth();
  const user = useUser();

  const dropdownContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const onElseWhereClick = (e: MouseEvent) => {
      if (dropdownContainerRef.current?.contains(e.target as Node) === false) {
        setShowUserInfo(false);
      }
    };

    document.body.addEventListener("click", onElseWhereClick);

    return () => document.body.removeEventListener("click", onElseWhereClick);
  }, []);

  return (
    <SidebarUserInfoUserContainer ref={dropdownContainerRef}>
      <SidebarUserInfoUserImageContainer
        onClick={() => setShowUserInfo(!showUserInfo)}
      >
        {/* I have no idea why. But without `referrerPolicy`, it won't work in development/on localhost */}
        {/* See: https://stackoverflow.com/a/61042200/6690391 */}
        <img
          src={user.imageUrl === undefined ? "/img/logo-2.svg" : user.imageUrl}
          alt="User Profile"
          referrerPolicy="no-referrer"
        />
      </SidebarUserInfoUserImageContainer>
      <SidebarUserInfoUserInfoWrapper $show={showUserInfo}>
        <h4>{user.name}</h4>
        <SidebarUserInfoLogoutIcon onClick={clearAndUnpersistUser}>
          <span className="material-symbols-outlined">logout</span>
          <p>Logout</p>
        </SidebarUserInfoLogoutIcon>
      </SidebarUserInfoUserInfoWrapper>
    </SidebarUserInfoUserContainer>
  );
};
