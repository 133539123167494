import { PatientType } from "../../../../../../../../../models/patient-dtos/patient-type";

export function isGlucosePointAboveRange(
  type: PatientType,
  glucoseMGPerDL: number,
  tag?: string
) {
  if (tag === "fasting") {
    if (type === "NormalOrHealthy" || type === "Prediabetic") {
      return glucoseMGPerDL > 100;
    } else if (type === "Type1Pediatric") {
      return glucoseMGPerDL > 144;
    } else {
      return glucoseMGPerDL > 130;
    }
  } else {
    if (type === "NormalOrHealthy" || type === "Prediabetic") {
      return glucoseMGPerDL > 140;
    } else {
      return glucoseMGPerDL > 180;
    }
  }
}
