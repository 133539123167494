import {
  ChartComponent,
  DateTime,
  IAxisLabelRenderEventArgs,
  Inject,
  ScatterSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  SplineSeries,
  StripLine,
} from "@syncfusion/ej2-react-charts";
import { useAGPReportLoadedResponse } from "../../../context/agp-report-loaded-response-context";
import { useDateRange } from "../../../../../../../components/date-range/context/date-range-context";
import { useCallback, useMemo, useRef } from "react";
import { DaysFilterOptions } from "../../../context/agp-days-filter-context";
import { randomUUID } from "../../../../../../../utils/random-uuid";
import { getInRangeNumbers } from "../../../../../../../utils/patient-type-utils";
import { buildPrimaryYAxis } from "./utils/build-primary-y-axis";
import { buildPrimaryXAxis } from "./utils/build-primary-x-axis";
import { buildOverlaySeries } from "../../agp-report-response-agp/agp-report-response-overlay/utils/series/build-overlay-series";

type AgpReportResponseMiniOverlayProps = {
  filter: DaysFilterOptions;
};

export function AgpReportResponseMiniOverlay(
  props: AgpReportResponseMiniOverlayProps
) {
  const { filter } = props;

  const { groupCgmEntriesByDate, patientDTO } = useAGPReportLoadedResponse();
  const { selectedDates } = useDateRange();

  const chartRef = useRef<ChartComponent | null>(null);
  const id = useMemo(() => `mini-overlay-syncfusion-chart-${randomUUID()}`, []);

  const handleChartLoaded = useCallback(() => {
    const chart = document.getElementById(id);

    if (chart) {
      chart.style.overflow = "visible";
    }
  }, [id]);

  const primaryxAxis = useMemo(
    () =>
      buildPrimaryXAxis(
        new Date(`2024-01-01T00:00:00`),
        new Date(`2024-01-02T00:00:00`)
      ),
    []
  );

  const maxY = 350;

  const inRangeNumbers = useMemo(() => getInRangeNumbers(patientDTO.type), [
    patientDTO,
  ]);

  const primaryYAxis = useMemo(() => buildPrimaryYAxis(maxY, inRangeNumbers), [
    maxY,
    inRangeNumbers,
  ]);

  const fitlerPatientCGMEntryDTOsByDay = useMemo(() => {
    const datesToFilter = selectedDates.datesWithinRange
      .filter((date) => filter.includes(date.weekDay))
      .map((item) => item.date);

    return groupCgmEntriesByDate.filter((entry) =>
      datesToFilter.includes(entry.date)
    );
  }, [groupCgmEntriesByDate, selectedDates.datesWithinRange, filter]);

  const overlaySeries = useMemo(
    () => buildOverlaySeries(fitlerPatientCGMEntryDTOsByDay),
    [fitlerPatientCGMEntryDTOsByDay]
  );

  return (
    <ChartComponent
      id={id}
      primaryXAxis={primaryxAxis}
      primaryYAxis={primaryYAxis}
      axisLabelRender={(args: IAxisLabelRenderEventArgs) => {
        args.labelStyle.color = "transparent";
      }}
      ref={chartRef}
      loaded={handleChartLoaded}
      height="80px"
      width="100%"
      enableAnimation={false}
    >
      <Inject services={[StripLine, SplineSeries, DateTime, ScatterSeries]} />
      <SeriesCollectionDirective>
        {overlaySeries.map((item) => (
          <SeriesDirective
            dataSource={item.series}
            animation={{ enable: false }}
            xName="x"
            yName="y"
            type="Scatter"
            name={`Overlay: ${item.day}s`}
            fill={item.seriesColor}
            width={2}
            opacity={0.5}
            splineType="Cardinal"
            cardinalSplineTension={0.5}
          ></SeriesDirective>
        ))}
      </SeriesCollectionDirective>
    </ChartComponent>
  );
}
