import styled from "styled-components/macro";
import {
  flexCenterCenter,
  flexCenterSpaceBetween,
} from "../../../../../../../styles/classes/gloabl-classes";
import { EmptyStateBody } from "../../../../../../../components/empty-state/styled-empty-state";

export const BGMGlucoseBGBody = styled.div`
  padding: 16px;
  ${flexCenterSpaceBetween}
  height: calc(100% - 37px);
  width: 50%;
  margin: 0 auto;

  ${EmptyStateBody} {
    margin: 0 auto;
    height: 100%;
  }

  @media screen and (max-width: 1440px) {
    width: 70%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const BgmGlucoseBGBodyChartAndDetailsContainer = styled.div`
  ${flexCenterCenter}
  width: 100%;
  height: 310px;
  position: relative;

  .arrow {
    background: transparent;
    border: 2px solid #000;
    width: 35px;
    height: 30px;
    position: absolute;
    left: 30px;
  }

  .arrow-top {
    top: 20px;
    border-bottom: 0px;
    border-right: 0px;
    border-radius: 8px 0px 0px 0px;
  }

  .arrow-bottom {
    bottom: 20px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px 0px 0px 8px;
  }
`;
