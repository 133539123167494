import {
  AGPReportResponseTimeRangesBody,
  AGPReportResponseTimeRangesBodyChartAndDetailsContainer,
  AGPReportResponseTimeRangesContainer,
  AGPReportResponseTimeRangesHeader,
} from "./styled-agp-report-response-time-ranges";
import { AGPReportResponseTimeRangesChart } from "./agp-report-response-time-ranges-chart/agp-report-response-time-ranges-chart";
import { AGPReportResponseTimeRangesDetails } from "./agp-report-response-time-ranges-details/agp-report-response-time-ranges-details";
import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { useMemo } from "react";
import { useDateRange } from "../../../../../../../components/date-range/context/date-range-context";
import { useAGPDaysFilter } from "../../../context/agp-days-filter-context";
import { useAGPReportLoadedResponse } from "../../../context/agp-report-loaded-response-context";
import { filterdByDaysGroupCgmEntriesByDate } from "../../utils/group-cgm-entries-by-date-filterd-by-days";

export const AGPReportResponseTimeRanges = () => {
  const {
    patientCGMEntryDTOs,
    groupCgmEntriesByDate,
  } = useAGPReportLoadedResponse();

  const { daysFilter } = useAGPDaysFilter();

  const { selectedDates } = useDateRange();
  const datesWithinRange = selectedDates.datesWithinRange;

  const groupCgmEntriesByDateFilterdByDays = useMemo(
    () =>
      filterdByDaysGroupCgmEntriesByDate(
        groupCgmEntriesByDate,
        daysFilter,
        datesWithinRange
      ),
    [groupCgmEntriesByDate, daysFilter, datesWithinRange]
  );

  if (patientCGMEntryDTOs.length === 0) {
    return (
      <AGPReportResponseTimeRangesContainer>
        <AGPReportResponseTimeRangesHeader>
          <p>Time Ranges</p>
        </AGPReportResponseTimeRangesHeader>
        <AGPReportResponseTimeRangesBody>
          <EmptyState
            button={<></>}
            hasHeader={false}
            hasSearch={false}
            text="No Data Within the selected dates"
            title=""
          />
        </AGPReportResponseTimeRangesBody>
      </AGPReportResponseTimeRangesContainer>
    );
  }

  return (
    <AGPReportResponseTimeRangesContainer>
      <AGPReportResponseTimeRangesHeader>
        <p>Time Ranges</p>
      </AGPReportResponseTimeRangesHeader>
      <AGPReportResponseTimeRangesBody>
        <AGPReportResponseTimeRangesBodyChartAndDetailsContainer>
          <div className="arrow arrow-top" />
          <AGPReportResponseTimeRangesChart
            groupCgmEntriesByDateFilterdByDays={
              groupCgmEntriesByDateFilterdByDays
            }
          />
          <AGPReportResponseTimeRangesDetails
            groupCgmEntriesByDateFilterdByDays={
              groupCgmEntriesByDateFilterdByDays
            }
          />
          <div className="arrow arrow-bottom" />
        </AGPReportResponseTimeRangesBodyChartAndDetailsContainer>
      </AGPReportResponseTimeRangesBody>
    </AGPReportResponseTimeRangesContainer>
  );
};
