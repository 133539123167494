import { Temporal } from "temporal-polyfill";
import { useDateRange } from "../context/date-range-context";
import {
  DateRangeButton,
  DateRangeButtonsContainer,
  DateRangeSelectedDatesContainer,
  DateRangeSelectedDatesText,
} from "./styled-date-range-selected-dates";

type DateRangeSelectedDatesProps = {
  handleResetDates: () => void;
  showResetDatesButton?: boolean;
};

export const DateRangeSelectedDates = (props: DateRangeSelectedDatesProps) => {
  const { handleResetDates, showResetDatesButton } = props;

  const {
    temporalStartDate,
    temporalEndDate,
    handleDecrementDates,
    handleIncrementDates,
    selectedDates,
  } = useDateRange();

  const selectedStartDate = selectedDates.temporalStartDate
    ? Temporal.PlainDate.from(selectedDates.temporalStartDate)
    : undefined;

  const selectedEndDate = selectedDates.temporalEndDate
    ? Temporal.PlainDate.from(selectedDates.temporalEndDate)
    : undefined;

  const startDate =
    selectedStartDate && selectedEndDate
      ? selectedStartDate.toLocaleString("en-AU", {
          month:
            selectedStartDate.month === selectedEndDate.month
              ? undefined
              : "short",
          day: "numeric",
          year: undefined,
        })
      : undefined;

  const endDate = selectedEndDate
    ? selectedEndDate.toLocaleString("en-AU", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    : undefined;

  return (
    <DateRangeSelectedDatesContainer>
      {temporalStartDate && temporalEndDate && (
        <>
          <DateRangeButtonsContainer>
            <DateRangeButton type="button" onClick={handleDecrementDates}>
              <span className="material-symbols-outlined">arrow_back_ios</span>
            </DateRangeButton>
            <DateRangeButton type="button" onClick={handleIncrementDates}>
              <span className="material-symbols-outlined">
                arrow_forward_ios
              </span>
            </DateRangeButton>
          </DateRangeButtonsContainer>
          <DateRangeSelectedDatesText>
            {startDate} - {endDate}
            <span>{selectedDates.count} Days</span>
          </DateRangeSelectedDatesText>
        </>
      )}
      {temporalStartDate !== undefined &&
        temporalEndDate !== undefined &&
        showResetDatesButton && (
          <span
            onClick={handleResetDates}
            className="material-symbols-outlined clear-dates-button"
          >
            close
          </span>
        )}
    </DateRangeSelectedDatesContainer>
  );
};
