import { createGlobalStyle } from "styled-components";

// Constants
export const NavbarHeight = `0px`;
export const SidebarWidth = `72px`;
export const SinglePatientSidebarWidth = `25%`;
export const transition = `all 0.3s ease`;

// Shadows
export const lightShadow = `box-shadow: 3px 0px 50px 21px rgba(0, 0, 0, 0.1);`;
export const lightShadowAlt = `box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
0px 4px 6px -2px rgba(16, 24, 40, 0.03);`;
export const lightShadowSimple = `box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);`;
export const lightShadowSecondary = `box-shadow: 0px 6px 14px rgba(45, 46, 61, 0.12);`;
export const lightShadowDropdownList = `box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
0px 4px 6px -2px rgba(16, 24, 40, 0.03);`;

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.bodyColor};
    font-family: "IBM Plex Sans Arabic", sans-serif;
  }

  button {
    border: 0;
    outline: 0;
    cursor: pointer;
    font-family: "IBM Plex Sans Arabic", sans-serif;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  span.material-symbols-outlined {
    user-select: none;
  }

  textarea {
    font-family: "IBM Plex Sans Arabic", sans-serif;
    border: 0;
    outline: 0;
    resize: none;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  // ==================== SCROLL-BAR  ====================
  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
    border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: ${({ theme }) => theme.colors.scrollbarThumb};
    border-radius: 0.5rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.colors.textColor};
  }
`;
