import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  flexCenterSpaceBetween,
  flexColCenterCenter,
  flexColCenterSpaceBetween,
  smallSemiBoldFont,
} from "../../../styles/classes/gloabl-classes";
import { SidebarWidth, transition } from "../../../styles/global-style";

export const SidebarContainer = styled.aside<{
  showResponsiveSideber: boolean;
}>`
  ${flexColCenterSpaceBetween}
  width: ${SidebarWidth};
  background: ${({ theme }) => theme.colors.primaryColor};
  color: ${({ theme }) => theme.colors.buttonColor};
  z-index: ${({ theme }) => theme.zIndex.z400};
  position: fixed;
  inset: 0;
  padding: ${({ theme }) => theme.padding.py_1};
  transition: ${transition};

  @media screen and (max-width: 500px) {
    z-index: ${({ theme }) => theme.zIndex.z900};
    width: ${({ showResponsiveSideber }) =>
      showResponsiveSideber ? "78%" : "0%"};
    overflow: ${({ showResponsiveSideber }) =>
      showResponsiveSideber ? "visible" : "hidden"};
    opacity: ${({ showResponsiveSideber }) =>
      showResponsiveSideber ? "1" : "0"};
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    background: url("/img/side-menu-mobile.svg") left center / cover no-repeat;
  }
`;

export const SidebarBackDrop = styled.div<{ showResponsiveSideber: boolean }>`
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
    position: fixed;
    transition: ${transition};
    inset: 0;
    z-index: ${({ theme }) => theme.zIndex.z800};
    background: ${({ theme }) => theme.newColors.backdrop};
    width: ${({ showResponsiveSideber }) =>
      showResponsiveSideber ? "100%" : "0%"};
    overflow: ${({ showResponsiveSideber }) =>
      showResponsiveSideber ? "visible" : "hidden"};
    opacity: ${({ showResponsiveSideber }) =>
      showResponsiveSideber ? "1" : "0"};
  }
`;

export const CloseIcon = styled.div`
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
    position: absolute;
    top: 2.5rem;
    right: 2rem;
    cursor: pointer;
  }
`;

export const SidebarHeaderContainer = styled.div`
  text-align: center;

  @media screen and (max-width: 500px) {
    ${flexCenterSpaceBetween}
    margin-right: auto;
    gap: 0rem 1rem;
    padding: 1rem;
  }
`;

export const SidebarHeaderLogo = styled.div`
  width: 40px;
  height: 40px;
`;

export const SidebarLogoTitle = styled.h1`
  font-size: ${({ theme }) => theme.typography.fontSize.mdFontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  margin: 0;

  @media screen and (max-width: 500px) {
    font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
  }
`;

export const SidebarLinksList = styled.ul`
  width: 100%;
  margin-bottom: auto;
`;

export const SidebarLinksListItem = styled.li<{ activeLink: boolean }>`
  transition: ${transition};
  color: ${({ theme, activeLink }) =>
    activeLink ? theme.colors.buttonColor : theme.colors.textColor};
  font-size: ${({ theme }) => theme.typography.fontSize.xsFontSize};
  margin: 1rem 0 0;
  border-left: 3px solid
    ${({ activeLink, theme }) =>
      activeLink ? theme.colors.containerColor : "transparent"};
  cursor: pointer;
  background: ${({ activeLink, theme }) =>
    activeLink ? theme.colors.activeLayoutSidebarLink : `transparent`};
  position: relative;

  p {
    margin: 0;
  }

  a {
    ${flexColCenterCenter}
    text-decoration: none;
    color: inherit;
  }

  img {
    filter: ${({ activeLink }) => (activeLink ? "invert(1)" : "invert(0.5)")};
  }

  @media screen and (max-width: 500px) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 0rem 1rem;
    padding: 1.5rem 0.5rem;
    font-size: ${({ theme }) => theme.typography.fontSize.regularFont};
    margin: 0rem;
  }
`;

export const ChatContNumber = styled.span`
  position: absolute;
  ${smallSemiBoldFont}
  background: ${({ theme }) => theme.colors.primaryColorAlt};
  border-radius: 100%;
  width: 20px;
  height: 20px;
  padding: 4px;
  ${flexCenterCenter}
  right: 7px;
  top: -10px;
  color: #fff;
  z-index: 401;
`;

// Mobile View
export const UserInfoContainer = styled.div`
  display: none;

  @media screen and (max-width: 500px) {
    ${flexCenter}
    gap: 0rem 1rem;
    margin: 2rem 0rem 1rem;
  }
`;

export const UserInfoImage = styled.div`
  width: 55px;
  height: 55px;

  img {
    width: 100%;
    border-radius: 50%;
  }
`;

export const UserInfoName = styled.p`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  color: ${({ theme }) => theme.colors.buttonColor};
  margin: 0;
`;

export const MobileLogoutContainer = styled.div`
  display: none;

  @media screen and (max-width: 500px) {
    ${flexCenter}
    cursor: pointer;
    gap: 0rem 1rem;
    margin: 0rem auto 2rem 1rem;
  }

  p {
    margin: 0;
  }
`;
