import styled from "styled-components/macro";
import { mediumFont } from "../../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../../styles/global-style";

export const AGPReportResponseDaysFilterContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  width: 860px;
  margin: 0 auto 16px;

  @media screen and (max-width: 1414px) {
    width: 100%;
  }
`;

export const AGPReportResponseDaysFilterList = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 890px;
  margin: 0px auto;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};

  @media screen and (max-width: 1440px) {
    margin: 0px;
    margin-left: auto;
    margin-right: 20px;
  }

  @media screen and (max-width: 1414px) {
    width: 100%;
    margin: 0px;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  }
`;

export const AGPReportResponseDaysFilterListItem = styled.li<{
  $active: boolean;
}>`
  cursor: pointer;
  border: 2px solid
    ${({ theme, $active }) =>
      $active ? theme.colors.primaryColorAlt : "transparent"};
  opacity: ${({ $active }) => ($active ? 1 : 0.7)};
  transition: ${transition};
  background: ${({ theme, $active }) =>
    $active ? `${theme.colors.primaryColorAlt}26` : theme.colors.bodyColor};

  p {
    ${mediumFont}
    margin: 0px;
    color: ${({ theme, $active }) =>
      $active ? theme.colors.titleColor : theme.colors.textColor};
    text-align: center;
  }
`;
