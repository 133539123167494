import styled from "styled-components/macro";
import { lightShadowAlt } from "../../../../../../../styles/global-style";

export const BGMGlucoseTagContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
  gap: 16px;
  width: 90%;
  margin: 0 auto;

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, 90%);
  }
`;

export const BGMGlucoseTagCard = styled.div`
  ${lightShadowAlt}
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
`;
