import { PropsWithChildren } from "react";
import {
  AGPReportLoadedResponse,
  AGPReportLoadedResponseProvider,
} from "./agp-report-loaded-response-context";
import { AGPLoadedResponseProvider } from "./agp-loaded-response-context";
import { AgpOrOverlayProvider } from "./agp-or-overlay-context";
import { AGPDaysFilterProvider } from "./agp-days-filter-context";

type AGPReportProvidersProps = PropsWithChildren<{
  cgmReportResponse: AGPReportLoadedResponse;
}>;

export const AGPReportProviders = (props: AGPReportProvidersProps) => {
  const { children, cgmReportResponse } = props;

  return (
    <AGPDaysFilterProvider>
      <AGPReportLoadedResponseProvider
        patientDTO={cgmReportResponse.patientDTO}
        patientCGMEntryDTOs={cgmReportResponse.patientCGMEntryDTOs}
        patientMealsDTOs={cgmReportResponse.patientMealsDTOs}
        patientInsulinLogDTOs={cgmReportResponse.patientInsulinLogDTOs}
        patientActivityLogStats={cgmReportResponse.patientActivityLogStats}
      >
        <AGPLoadedResponseProvider>
          <AgpOrOverlayProvider>{children}</AgpOrOverlayProvider>
        </AGPLoadedResponseProvider>
      </AGPReportLoadedResponseProvider>
    </AGPDaysFilterProvider>
  );
};
