import { Modal } from "../../../../../../../../components/modal/modal";
import { AgpReportResponseChartModalBody } from "./agp-report-response-chart-modal-body/agp-report-response-chart-modal-body";

type AgpReportResponseChartModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  modalBodyKey: number;
  date: string;
};

export const AgpReportResponseChartModal = (
  props: AgpReportResponseChartModalProps
) => {
  const { isModalOpen, closeModal, modalBodyKey, date } = props;

  return (
    <Modal handleHideModal={closeModal} showModal={isModalOpen}>
      <AgpReportResponseChartModalBody
        key={modalBodyKey}
        date={date}
        closeModal={closeModal}
        isModalOpen={isModalOpen}
      />
    </Modal>
  );
};
